import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import { SbTextarea, SbButton } from 'skybase-ui/skybase-components'
import { Mutation } from '@apollo/react-components'
import { modifyMeasurementCommentsMutation } from '../../containers/measurements-page/measurements-page-queries'
import { messages as t } from './comment-box-i18n'

class _CommentBox extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
    comments: PropTypes.string,
    onChange: PropTypes.func,
    showSuccessToast: PropTypes.func,
    showErrorToast: PropTypes.func,
  }

  static defaultProps = {
    onChange: () => {},
    showSuccessToast: () => {},
    showErrorToast: () => {},
  }

  constructor(props) {
    super(props)

    const { comments } = this.props
    this.state = { userComments: comments ? comments : '', inFocus: false }
  }

  componentDidUpdate(prevProps) {
    const { comments } = this.props
    if (prevProps.comments !== comments) {
      this.setState({userComments: comments ? comments : ''});
    }
  }


  toolbar() {
    const {
      intl: { formatMessage: _ },
      comments,
      onChange,
      showSuccessToast,
      showErrorToast,
      measurementId,
      disabled,
    } = this.props
    const { inFocus, userComments } = this.state

    if (!inFocus || disabled) return (<></>)
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Mutation
          mutation={modifyMeasurementCommentsMutation}
          variables={{ id: measurementId, comments: userComments}}
          onCompleted={data => {
            showSuccessToast(`${_(t.csvIsBeingReCreaded)}...`)
          }}
          onError={err => {
            showErrorToast(_(t.errorOnReCreation))
          }}
        >
          {(postMutation, { loading }) => (
            <SbButton
              className='primary'
              icon='sbi-replace'
              onClick={() => {
                postMutation()
                this.setState({inFocus: false})
                onChange({focused: false})
              }}
            >
              {_(t.saveAndRecreateCSV)}
            </SbButton>
          )}
        </Mutation>
        <SbButton onClick={() => {
          this.setState({userComments: comments, inFocus: false})
          onChange({focused: false})
        }}
        >
          {_(t.cancel)}
        </SbButton>
      </div>
    )
  }


  render() {
    const { userComments, inFocus} = this.state
    const { onChange, disabled } = this.props

    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <SbTextarea
            style={{width: '100%', margin: '5px'}}
            name="normal"
            disabled={disabled}
            id="textarea-normal-id"
            className="textarea-normal-class"
            onClick={(event) => {
              if (event.detail === 2) {
                if (!inFocus) {
                  document.getSelection().collapseToEnd()
                  this.setState({inFocus: true})
                  onChange({focused: true})
                }
              }
            }}
            onChange={(event) => {
              if (!inFocus) {
                this.setState({inFocus: true})
                onChange({focused: true})
              }
              this.setState({userComments: event.target.value})
            }}
            value={userComments}
          />
        </div>
        {this.toolbar()}
      </div>
    )
  }
}

export const CommentBox = injectIntl(_CommentBox)
