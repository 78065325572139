import { OAuth } from 'skybase-oauth/oauth'

// The Oauth Config
export const oAuthConfig = {
  IDENTITY_SERVER_URL: 'https://auth.hub.kistler.com',
  CLIENT_ID: 'kienbaum',
  REDIRECT_URI: `${window.location.protocol}//${window.location.host}`,
  SILENT_RENEW_REDIRECT_URI: `${window.location.protocol}//${window.location.host}/redirect.html`,
  POST_LOGOUT_URI: `${window.location.protocol}//${window.location.host}`,
  SCOPES: 'kiconnect.identities kiconnect.oauth',
}

// Helper functions
export const currentUserId = () => OAuth.oAuthHandler.getAccessToken().decodedAccessToken.sub