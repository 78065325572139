import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'
import classNames from 'classnames'

import { messages as t } from './status-bullet-i18n'

import './status-bullet.scss'

const _StatusBullet = props => {
  const {
    enabled,
    unknown,
    enabledText,
    disabledText,
    unknownText,
    intl: { formatMessage: _ },
  } = props

  let text
  if (unknown) {
    text = unknownText || _(t.unknown)
  } else if (enabled) {
    text = enabledText || _(t.enabled)
  } else {
    text = disabledText || _(t.disabled)
  }

  return (
    <div className="fl-row fl-align-items-center">
      <span
        className={classNames('status-bullet', {
          enabled: !unknown && enabled,
          disabled: !unknown && !enabled,
          unknown,
        })}
      />
      {text}
    </div>
  )
}

_StatusBullet.propTypes = {
  enabled: PropTypes.bool.isRequired,
  unknown: PropTypes.bool,
  enabledText: PropTypes.string,
  disabledText: PropTypes.string,
  unknownText: PropTypes.string,
  intl: intlShape.isRequired,
}

_StatusBullet.defaultProps = {
  unknown: false,
  enabledText: undefined,
  disabledText: undefined,
  unknownText: undefined,
}

export const StatusBullet = injectIntl(_StatusBullet)
