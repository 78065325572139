import { defineMessages } from 'react-intl'
import { messages as commonMesssages } from '@/common/i18n'

export const messages = {
  appName: commonMesssages.appName,
  ...defineMessages({
    pageName: {
      id: 'about-page.pageName',
      defaultMessage: 'About',
    },
    pageIntro: {
      id: 'about-page.pageIntro',
      defaultMessage: 'Welcome to SkyBase Demo application',
    },
  }),
}
