import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { SbFullLayout } from 'skybase-ui/skybase-components/layouts/sb-full-layout'
import { AboutAppLinksWidget, AboutAppWidget } from 'skybase-ui/widgets'
import config from '@/config'

import { messages as t } from './about-page-i18n'

/**
 * @class
 * @classdec
 * @desc
 * About page component.
 *
 * @public
 *
 */
class _AboutPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
  }

  static defaultProps = {}

  render() {
    const copyrightYear = config.copyright
    const {
      intl: { formatMessage: _ },
    } = this.props
    return (
      <SbFullLayout
        title={_(t.pageName)}
      >
        <div>
          <AboutAppWidget copyrightYear={`${copyrightYear}`} deviceType={_(t.appName)} licensesUrl="/about/licenses" />
          <AboutAppLinksWidget />
        </div>
      </SbFullLayout>
    )
  }
}

export const AboutPage = injectIntl(_AboutPage)
