import { gql } from '@apollo/client'

const fields = `
  id, accountId, firstName, lastName, type, email, disabled, connections { id, firstName, lastName }
`

export const allAccountsQuery = gql`
  query {
    allAccounts {
      ${fields}
    }
  }
`

export const athleteDetailsQuery = gql`
  query athleteDetails($id: ID!) {
    athleteDetails(id: $id) {
      id
      birthdate
      female
    }
  }
`

export const updateAthleteMutation = gql`
  mutation updateAthlete($id: ID!, $firstName: String!, $lastName: String!, $connectionIds: [ID]!, $female: Boolean!, $birthdate: DateTime!, $disabled: Boolean, $email: EmailAddress) {
    updateAccount(id: $id, firstName: $firstName, lastName: $lastName, type: Athlete, disabled: $disabled, email: $email) {
      ${fields}
    },
    updateAthlete(id: $id, tutorIds: $connectionIds, female: $female, birthdate: $birthdate) {
      id, female, birthdate, firstName, lastName
    },
  }
`

export const updateTutorMutation = gql`
  mutation updateAthlete($id: ID!, $firstName: String!, $lastName: String!, $connectionIds: [ID]! $disabled: Boolean, $email: EmailAddress) {
    updateAccount(id: $id, firstName: $firstName, lastName: $lastName, type: Tutor, disabled: $disabled, email: $email) {
      ${fields}
    },
    updateTutor(id: $id, athleteIds: $connectionIds) {
      id, firstName, lastName
    },
  }
`

export const deleteAthleteMutation = gql`
  mutation deleteAthlete($id: ID!) {
    deleteAthlete(id: $id) {
      id
    }
  }
`
