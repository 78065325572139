import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  SbFullLayout,
  SbDataTable,
  SbDateTime,
  SbDropdown,
  SbButton,
  SbInlineMessage,
  SbCheckbox,
  SbLabel,
  SbLoader,
} from 'skybase-ui/skybase-components'
import { sbLocaleShape, injectSbLocale } from 'skybase-ui/skybase-components/sb-locale'
import { INLINE_MESSAGE_TYPE_INFO } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { showSuccessToast, showErrorToast } from 'skybase-ui/skybase-components/sb-toastr'

import { withAclList } from 'skybase-oauth/auth/with-acl-list'
import { AclControl } from 'skybase-oauth/auth/acl-control'

import { FileDownloadButton } from '@/components/file-download-button'
import { CommentBox } from '@/components/comment-box/comment-box'
import { openModal } from 'skybase-ui/skybase-core/base/actions'

import { parameterUnits } from '@/components/parameter-editor/constants'

import { Query, Mutation, Subscription } from '@apollo/react-components'
import {
  findMeasurementsQuery,
  measurementQuery,
  filterDataQuery,
  athleteFilterDataQuery,
  resetSessionMutation,
  assignedMeasurementAddedSubscription,
  assignedMeasurementUpdatedSubscription,
  reAnalyzeMeasurementMutation,
  reAssignMeasurementMutation,
  toggleMeasurementInvalidationMutation,
  currentSessionQuery,
  currentSessionSubscription,
  currentAnalysisStatusQuery,
  currentAnalysisStatusSubscription,
} from './measurements-page-queries'

import { NEW_SESSION_MODAL_ID, EDIT_SESSION_PARAMETERS_MODAL_ID } from './constants'
import { messages as t } from './measurements-page-i18n'
import {
  downloadRawDataPermission,
  createSessionPermission,
  reAnalyzePermission,
  readOwnMeasurementsPermission,
  invalidatePermission,
  measurementCommentsPermission,
} from './measurements-page-permissions'
import { MaxSprintCharacteristics } from './characteristics/maxsprint'
import { LongJumpCharacteristics } from './characteristics/longjump'
import { SprintStartCharacteristics } from './characteristics/sprintstart'
import { SyncVideoPlayer } from './sync-video-player'

import './measurements-page.scss'

class _MeasurementsPage extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    sbLocale: sbLocaleShape.isRequired,
    showErrorToast: PropTypes.func.isRequired,
    showSuccessToast: PropTypes.func.isRequired,
    newSessionModal: PropTypes.func.isRequired,
    editParametersModal: PropTypes.func.isRequired,
    aclList: PropTypes.shape({
      [readOwnMeasurementsPermission]: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {
      selectedMeasurementId: null,
      pageSize: 10,
      pageNumber: 1,
      dateFilter: null,
      athleteFilter: null,
      movementFilter: null,
      includeInvalidFilter: false,
      currentSession: false,
      myAccountId: null,
      sessionSubscription: undefined,
      liveMode: false,
      analysisStatus: undefined,
      analysisStatusSubscription: undefined,
      editAthlete: null,
      comments: '',
      keyboardShortcutsEnabled: true,
    }
  }

  rowFormatter = row => {
    const { selectedMeasurementId } = this.state
    const selected = row.id === selectedMeasurementId ? 'selected' : ''

    return {
      onClick: () => {
        this.setState({
          selectedMeasurementId: row.id === selectedMeasurementId ? null : row.id,
          validated: false,
          editAthlete: null,
        })
      },
      className: classNames({ selected }),
      style: row.invalid ? { color: 'red' } : {},
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
      sbLocale: { formatDateTime },
      showSuccessToast,
      showErrorToast,
      newSessionModal,
      editParametersModal,
      aclList,
    } = this.props

    const {
      pageSize,
      pageNumber,
      dateFilter,
      athleteFilter,
      movementFilter,
      includeInvalidFilter,
      currentSession,
      sessionSubscription,
      liveMode,
      selectedMeasurementId,
      myAccountId,
      analysisStatus,
      analysisStatusSubscription,
      editAthlete,
      keyboardShortcutsEnabled,
    } = this.state

    // Helpers for analysisStatus
    const isQueued = (id, type = null) => {
      if (!analysisStatus?.queued) return false
      if (type) {
        return Object.keys(analysisStatus?.queued).includes(`${id}_${type}`)
      } else {
        return (
          Object.keys(analysisStatus?.queued).includes(`${id}_Report`) ||
          Object.keys(analysisStatus?.queued).includes(`${id}_Characteristics`)
        )
      }
    }

    const isRunning = (id, type = null) =>
      analysisStatus?.current &&
      analysisStatus.current.id === id &&
      (type === null || analysisStatus.current.type === type)

    const isAthlete = aclList[readOwnMeasurementsPermission]
    return (
      <SbFullLayout title={_(t.pageName)}>
        <div>
          <Query
            query={currentSessionQuery}
            fetchPolicy="no-cache"
            onCompleted={data => {
              this.setState({
                currentSession: data.currentSession,
                liveMode: Boolean(data.currentSession),
                myAccountId: data.myAccount?.accountId,
              })
            }}
          >
            {({ data, subscribeToMore }) => {
              if (!sessionSubscription) {
                this.setState({
                  sessionSubscription: subscribeToMore({
                    document: currentSessionSubscription,
                    updateQuery: (prev, { subscriptionData }) => {
                      if (subscriptionData?.data) {
                        this.setState({
                          currentSession: subscriptionData?.data.currentSession,
                          liveMode: Boolean(subscriptionData?.data.currentSession),
                        })
                      }
                    },
                  }),
                })
              }
              return null
            }}
          </Query>
          <Query
            query={currentAnalysisStatusQuery}
            fetchPolicy="no-cache"
            onCompleted={data => {
              this.setState({
                analysisStatus: data.analysisStatus?.status,
              })
            }}
          >
            {({ data, subscribeToMore }) => {
              if (!analysisStatusSubscription) {
                this.setState({
                  analysisStatusSubscription: subscribeToMore({
                    document: currentAnalysisStatusSubscription,
                    updateQuery: (prev, { subscriptionData }) => {
                      if (subscriptionData?.data) {
                        this.setState({
                          analysisStatus: subscriptionData?.data.analysisStatus?.status,
                        })
                      }
                    },
                  }),
                })
              }
              return null
            }}
          </Query>

          <React.Fragment>
            {currentSession && (
              <React.Fragment>
                <h1>{_(t.pageName)}</h1>
                <SbInlineMessage
                  type={INLINE_MESSAGE_TYPE_INFO}
                  style={{ width: '100%' }}
                  message={
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <FormattedMessage
                          {...t.aSessionIsRunning}
                          values={{
                            tutor: (
                              <b>
                                {currentSession?.measurer?.firstName} {currentSession?.measurer?.lastName}
                              </b>
                            ),
                            movement: <b>{currentSession?.movement?.name}</b>,
                            athletes: (
                              <b>
                                {currentSession?.athletes?.length}{' '}
                                {currentSession?.athletes?.length === 1
                                  ? _(t.sessionIsRunningAthlete)
                                  : _(t.sessionIsRunningAthletes)}
                              </b>
                            ),
                          }}
                        />
                        .
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {(true || (myAccountId && myAccountId === currentSession?.measurer?.accountId)) && (
                          <React.Fragment>
                            <SbButton
                              icon="sbi-pencil-filled"
                              style={{ marginRight: '1em' }}
                              onClick={() => {
                                editParametersModal({
                                  athletes: currentSession.athletes,
                                  movement: currentSession.movement,
                                  sessionId: currentSession.id,
                                })
                              }}
                            >
                              {_(t.editParameters)}
                            </SbButton>
                            <Mutation
                              mutation={resetSessionMutation}
                              onCompleted={() => {
                                showSuccessToast(_(t.successfulTermination))
                              }}
                              onError={() => {
                                showErrorToast(_(t.errorOnTermination))
                              }}
                            >
                              {(postMutation, { loading }) => (
                                <SbButton
                                  icon="sbi-close"
                                  style={{ marginRight: '2em' }}
                                  loading={loading}
                                  onClick={() => {
                                    postMutation()
                                  }}
                                >
                                  {_(t.terminateSession)}
                                </SbButton>
                              )}
                            </Mutation>
                          </React.Fragment>
                        )}
                        <SbCheckbox
                          checked={liveMode}
                          type="switch-control"
                          onChange={checked => this.setState({ liveMode: checked })}
                          labelPosition="left"
                        >
                          <b>{_(t.enableLiveMode)}</b>
                        </SbCheckbox>
                      </div>
                    </div>
                  }
                />
              </React.Fragment>
            )}
            {!currentSession && (
              <div className="sb-heading fl-row fl-align-items-center">
                <h1>{_(t.pageName)}</h1>
                <AclControl writePermissions={[createSessionPermission]}>
                  <SbButton
                    className={'sb-btn-with-icon-text primary'}
                    style={{ top: '2px' }}
                    onClick={() => newSessionModal()}
                  >
                    <i className="sbi-plus" />
                    {_(t.newSession)}
                  </SbButton>
                </AclControl>
              </div>
            )}
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1, flexBasis: 0 }}>
                <div style={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between' }}>
                  <Query query={isAthlete ? athleteFilterDataQuery : filterDataQuery}>
                    {({ data, loading }) => (
                      <React.Fragment>
                        {!isAthlete && (
                          <SbDropdown
                            title={_(t.chooseAthletes)}
                            disabled={currentSession && liveMode}
                            selected={athleteFilter}
                            style={{ width: '45%' }}
                            multiSelect
                            items={
                              loading || !data
                                ? []
                                : data.assignedAthletes.map(a => ({
                                    id: a.id,
                                    value: a.id,
                                    name: `${a.firstName} ${a.lastName}`,
                                  }))
                            }
                            onChange={chosen => {
                              this.setState({ athleteFilter: chosen.length ? chosen : null })
                            }}
                            maxHeight={350}
                          />
                        )}
                        <SbDropdown
                          title={_(t.chooseMovements)}
                          disabled={(currentSession && liveMode) || !data?.allMovements?.length}
                          selected={movementFilter}
                          style={{ width: '45%' }}
                          multiSelect
                          items={
                            loading || !data
                              ? []
                              : data.allMovements.map(a => ({
                                  id: a.id,
                                  value: a.id,
                                  name: a.name,
                                }))
                          }
                          onChange={chosen => {
                            this.setState({ movementFilter: chosen.length ? chosen : null })
                          }}
                          maxHeight={350}
                        />
                      </React.Fragment>
                    )}
                  </Query>
                </div>
                <div style={{ display: 'flex', marginBottom: '1em', justifyContent: 'space-between' }}>
                  <SbDateTime
                    type={'date'}
                    disabled={currentSession && liveMode}
                    style={{ width: '45%' }}
                    value={dateFilter}
                    onChange={chosen => {
                      this.setState({ dateFilter: chosen.value })
                    }}
                  />
                  <div style={{ width: '45%', display: 'flex', justifyContent: 'space-between' }}>
                    <SbCheckbox
                      checked={includeInvalidFilter}
                      type="switch-control"
                      style={{ paddingTop: '0.6em' }}
                      onChange={checked => this.setState({ includeInvalidFilter: checked })}
                    >
                      {_(t.show)} <span className="sbi-trash" />
                    </SbCheckbox>
                    <SbButton
                      icon="sbi-close"
                      disabled={!(dateFilter || athleteFilter || movementFilter) || (currentSession && liveMode)}
                      onClick={() => {
                        this.setState({ dateFilter: null, athleteFilter: null, movementFilter: null })
                      }}
                    />
                  </div>
                </div>
                <Query
                  query={findMeasurementsQuery}
                  fetchPolicy="network-only"
                  variables={{
                    skip: (pageNumber - 1) * pageSize,
                    take: pageSize,
                    dateFilter,
                    athleteFilter,
                    movementFilter,
                    sessionId: currentSession && liveMode ? currentSession.id : null,
                    includeInvalid: includeInvalidFilter,
                  }}
                >
                  {({ data, loading, refetch }) => {
                    const formattedData =
                      loading || !data
                        ? []
                        : data.findMeasurements.measurements.map(m => ({
                            id: m?.id,
                            timestamp: formatDateTime(new Date(m?.timestamp)),
                            athlete: `${m?.athlete?.firstName} ${m?.athlete?.lastName}`,
                            movement: m?.movement?.name,
                            invalid: m?.invalid,
                          }))
                    return (
                      <div>
                        <Subscription
                          subscription={assignedMeasurementAddedSubscription}
                          onSubscriptionData={({ subscriptionData }) => {
                            refetch()
                            this.setState({
                              selectedMeasurement: subscriptionData?.data?.assignedMeasurementAdded,
                            })
                          }}
                        />
                        <Subscription
                          subscription={assignedMeasurementUpdatedSubscription}
                          onSubscriptionData={({ subscriptionData }) => {
                            const measurement = subscriptionData?.data?.assignedMeasurementUpdated
                            if (selectedMeasurementId && measurement?.id === selectedMeasurementId) {
                              this.setState({ selectedMeasurement: measurement })
                            }
                          }}
                        />
                        <SbDataTable
                          id="measurements-table"
                          className="list-table"
                          cellFormatter={(value, key, row) => {
                            if (key === 'analysis') {
                              if (isRunning(row.id)) {
                                return <SbLoader show size={12} />
                              } else if (isQueued(row.id)) {
                                return <span className="sbi-time" />
                              }
                              return null
                            }
                            return value
                          }}
                          rowFormatter={row => this.rowFormatter(row)}
                          columns={[
                            { name: 'timestamp', label: _(t.dateTime), headerStyle: { width: '35%' } },
                            { name: 'athlete', label: _(t.athlete), headerStyle: { width: '28%' } },
                            { name: 'movement', label: _(t.movement), headerStyle: { width: '27%' } },
                            { name: 'analysis', label: ` `, headerStyle: { width: '10%' } },
                          ]}
                          emptyMessage={
                            currentSession && liveMode ? _(t.noMeasurementsForSession) : _(t.noMeasurementsFound)
                          }
                          data={formattedData}
                          sortable={false}
                          loading={loading}
                          enablePagination
                          asyncData={true}
                          paginationProps={{
                            pageNumber,
                            pageSize,
                            goToPage: false,
                            pageSizeLabel: _(t.showRecords),
                            previousLabel: '< ' + _(t.previous),
                            nextLabel: _(t.next) + ' >',
                            totalCount: data?.findMeasurements?.totalCount || null,
                            onChange: p => {
                              this.setState(p)
                            },
                          }}
                        />
                      </div>
                    )
                  }}
                </Query>
              </div>
              <div style={{ width: '1px', margin: '6px 20px', background: '#ccc' }}></div>
              <div style={{ flexGrow: 2, flexBasis: 0, marginRight: '20px' }}>
                {selectedMeasurementId && (
                  <Query query={measurementQuery} variables={{ id: selectedMeasurementId }}>
                    {({ loading, data }) => {
                      const selectedMeasurement = data?.measurement

                      const videoIds = [0, 1].map(
                        i => selectedMeasurement?.files?.find(f => f.type === 'RawVideo' && f.cameraId === i)?.id,
                      )
                      const shortReportId = selectedMeasurement?.files?.find(f => f.type === 'ShortReport')?.id
                      const reportId = selectedMeasurement?.files?.find(f => f.type === 'Report')?.id
                      const rawFileId = selectedMeasurement?.files?.find(f => f.type === 'ForceData')?.id
                      const csvFileId = selectedMeasurement?.files?.find(f => f.type === 'CsvData')?.id
                      const csvResultsId = selectedMeasurement?.files?.find(f => f.type === 'CsvResults')?.id

                      const fileNameTimeStamp = selectedMeasurement?.timestamp
                        ? formatDateTime(new Date(selectedMeasurement?.timestamp)).replace(':', '-')
                        : ''
                      const fileNameSuffix = `${selectedMeasurement?.athlete?.firstName}_${selectedMeasurement?.athlete?.lastName}_${selectedMeasurement?.movement?.name}_${fileNameTimeStamp}`

                      return (
                        <React.Fragment>
                          {loading && (
                            <div style={{ textAlign: 'center' }}>
                              <SbLoader show style={{ marginTop: '2em' }} />
                            </div>
                          )}
                          {selectedMeasurement && (
                            <React.Fragment>
                              <h2>{_(t.measurementDetails)}</h2>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ width: '48%' }}>
                                  <SbLabel title={_(t.athlete)} inline>
                                    {!editAthlete && (
                                      <span>
                                        <b>{`${selectedMeasurement.athlete.firstName} ${selectedMeasurement.athlete.lastName}`}</b>
                                        {currentSession &&
                                          currentSession.id === selectedMeasurement.sessionId &&
                                          !selectedMeasurement.invalid && (
                                            <AclControl writePermissions={[reAnalyzePermission]}>
                                              <SbButton
                                                icon="sbi-pencil-filled"
                                                style={{ marginLeft: '1em' }}
                                                onClick={() => {
                                                  this.setState({
                                                    editAthlete: selectedMeasurement.athlete.id,
                                                  })
                                                }}
                                              />
                                            </AclControl>
                                          )}
                                      </span>
                                    )}
                                    {editAthlete && (
                                      <span>
                                        <SbDropdown
                                          selected={editAthlete}
                                          style={{ top: '-2px' }}
                                          items={currentSession?.athletes?.map(a => ({
                                            id: a.id,
                                            value: a.id,
                                            name: `${a.firstName} ${a.lastName}`,
                                          }))}
                                          onChange={chosen => {
                                            this.setState({ editAthlete: chosen })
                                          }}
                                        />
                                        <Mutation
                                          mutation={reAssignMeasurementMutation}
                                          onCompleted={({ data }) => {
                                            showSuccessToast(_(t.measurementHasBeenReAssigned))
                                            this.setState({
                                              selectedMeasurement: { ...data?.reAssignMeasurement },
                                              editAthlete: null,
                                            })
                                          }}
                                          onError={err => {
                                            showErrorToast(_(t.errorOnReAssignment))
                                          }}
                                        >
                                          {(postMutation, { loading }) => (
                                            <SbButton
                                              icon="sbi-checkhook"
                                              loading={loading}
                                              disabled={loading}
                                              style={{ marginLeft: '1em' }}
                                              onClick={() => {
                                                if (editAthlete !== selectedMeasurement.athlete.id) {
                                                  postMutation({
                                                    variables: { id: selectedMeasurementId, newAthleteId: editAthlete },
                                                  })
                                                } else {
                                                  this.setState({ editAthlete: null })
                                                }
                                              }}
                                            />
                                          )}
                                        </Mutation>
                                      </span>
                                    )}
                                  </SbLabel>
                                  <SbLabel title={_(t.tutor)} inline>
                                    <b>{`${selectedMeasurement.measurer.firstName} ${selectedMeasurement.measurer.lastName}`}</b>
                                  </SbLabel>
                                  {rawFileId &&
                                    (shortReportId ||
                                      isQueued(selectedMeasurement.id, 'Report') ||
                                      isRunning(selectedMeasurement.id, 'Report')) && (
                                      <SbLabel title={_(t.shortReport)} inline>
                                        <FileDownloadButton
                                          fileId={shortReportId}
                                          fileName={`ShortReport_${fileNameSuffix}.pdf`}
                                          className={'sb-btn-with-icon-text'}
                                          loading={isRunning(selectedMeasurement.id, 'Report')}
                                          disabled={!shortReportId}
                                          icon={shortReportId ? 'sbi-file-pdf' : 'sbi-time'}
                                        >
                                          {_(t.download)}
                                        </FileDownloadButton>
                                      </SbLabel>
                                    )}
                                  {rawFileId && (
                                    <AclControl writePermissions={[downloadRawDataPermission]}>
                                      <SbLabel title={_(t.rawData)} inline>
                                        <FileDownloadButton
                                          fileNames={{
                                            csv: {
                                              fileName: `ForceData_${fileNameSuffix}.csv`,
                                              fileId: csvFileId
                                            },
                                            mf4: {
                                              fileName: `ForceData_${fileNameSuffix}.mf4`,
                                              fileId: rawFileId
                                            }
                                          }}
                                          className={'sb-btn-with-icon-text'}
                                          icon={'sbi-file-other'}
                                        >
                                          {_(t.download)}
                                        </FileDownloadButton>
                                      </SbLabel>
                                    </AclControl>
                                  )}
                                </div>
                                <div style={{ width: '48%' }}>
                                  <SbLabel title={_(t.dateTime)} inline>
                                    <b>{formatDateTime(new Date(selectedMeasurement.timestamp))}</b>
                                  </SbLabel>
                                  <SbLabel title={_(t.movement)} inline>
                                    <b>{selectedMeasurement.movement.name}</b>
                                  </SbLabel>
                                  {rawFileId &&
                                    (reportId ||
                                      isQueued(selectedMeasurement.id, 'Report') ||
                                      isRunning(selectedMeasurement.id, 'Report')) && (
                                      <SbLabel title={_(t.report)} inline>
                                        <FileDownloadButton
                                          fileId={reportId}
                                          fileName={`Report_${fileNameSuffix}.pdf`}
                                          className={'sb-btn-with-icon-text'}
                                          loading={isRunning(selectedMeasurement.id, 'Report')}
                                          disabled={!reportId}
                                          icon={reportId ? 'sbi-file-pdf' : 'sbi-time'}
                                        >
                                          {_(t.download)}
                                        </FileDownloadButton>
                                      </SbLabel>
                                    )}
                                  {rawFileId &&
                                    (reportId ||
                                      isQueued(selectedMeasurement.id, 'CsvResults') ||
                                      isRunning(selectedMeasurement.id, 'CsvResults')) && (
                                      <SbLabel title={_(t.results)} inline>
                                        <FileDownloadButton
                                          fileId={csvResultsId}
                                          fileName={`Results_${fileNameSuffix}.csv`}
                                          className={'sb-btn-with-icon-text'}
                                          loading={isQueued(selectedMeasurement.id, 'CsvResults') ||
                                                   isRunning(selectedMeasurement.id, 'CsvResults')}
                                          disabled={!csvResultsId}
                                          icon={csvResultsId ? 'sbi-file-pdf' : 'sbi-time'}
                                        >
                                          {_(t.download)}
                                        </FileDownloadButton>
                                      </SbLabel>
                                    )}
                                </div>
                              </div>
                              <AclControl writePermissions={[measurementCommentsPermission]}>
                                <div style={{ width: '100%' }}>
                                  <SbLabel title={_(t.comments)} inline>
                                    <CommentBox
                                      measurementId={selectedMeasurement.id}
                                      comments={selectedMeasurement.comments}
                                      showSuccessToast={showSuccessToast}
                                      showErrorToast={showErrorToast}
                                      disabled={isQueued(selectedMeasurement.id) || isRunning(selectedMeasurement.id)}
                                      onChange={(event) => {
                                        this.setState({keyboardShortcutsEnabled: !event.focused})
                                      }}
                                    />
                                  </SbLabel>
                                </div>
                              </AclControl>
                              {selectedMeasurement.parameters && (
                                <SbLabel title={_(t.parameters)} inline style={{ marginTop: '2em' }}>
                                  <SbDataTable
                                    id="parametersTable"
                                    style={{ width: '100%' }}
                                    columns={Object.keys(selectedMeasurement.parameters).map(p => ({
                                      name: p,
                                      label: (
                                        <span>
                                          {t[p] ? _(t[p]) : p}:{' '}
                                          <b>{`${selectedMeasurement.parameters[p]} ${parameterUnits[p] || ''}`}</b>
                                        </span>
                                      ),
                                      headerStyle: { fontWeight: 'normal', padding: 0 },
                                    }))}
                                    data={[]}
                                    sortable={false}
                                  />
                                </SbLabel>
                              )}
                              {videoIds.some(v => v) && (
                                <SyncVideoPlayer
                                  videoIds={videoIds}
                                  measurementId={selectedMeasurement.id}
                                  characteristics={selectedMeasurement.characteristics}
                                  keyboardShortcutsEnabled={keyboardShortcutsEnabled}
                                  forceSuffixes={
                                    selectedMeasurement.movement.id === 'sprint' ? ['Front', 'Rear'] : undefined
                                  }
                                />
                              )}
                              {!rawFileId && (
                                <div style={{ marginTop: '2em', textAlign: 'center' }}>
                                  <span className="sbi-alert" /> {_(t.noMeasurementDataFound)}
                                </div>
                              )}
                              {rawFileId && (
                                <div style={{ textAlign: 'center' }}>
                                  {(!selectedMeasurement.characteristics ||
                                    !Object.keys(selectedMeasurement.characteristics)?.length) && (
                                    <React.Fragment>
                                      {isRunning(selectedMeasurement.id, 'Characteristics') && (
                                        <SbLoader
                                          show
                                          loadingText={_(t.characteristicsLoading)}
                                          style={{ marginTop: '2em' }}
                                        />
                                      )}
                                      {isQueued(selectedMeasurement.id, 'Characteristics') && (
                                        <div style={{ marginTop: '2em' }}>
                                          <span className="sbi-time" /> {_(t.analysisIsQueued)}...
                                        </div>
                                      )}
                                      {!isQueued(selectedMeasurement.id, 'Characteristics') &&
                                        !isRunning(selectedMeasurement.id, 'Characteristics') && (
                                          <div style={{ marginTop: '2em' }}>
                                            <span className="sbi-big-error" /> {_(t.noCharacteristics)}!
                                          </div>
                                        )}
                                    </React.Fragment>
                                  )}
                                  {selectedMeasurement.characteristics &&
                                    Object.keys(selectedMeasurement.characteristics)?.length &&
                                    selectedMeasurement.movement.id === 'sprint' && (
                                      <SprintStartCharacteristics
                                        measurementId={selectedMeasurement.id}
                                        characteristics={selectedMeasurement.characteristics}
                                      />
                                    )}
                                  {selectedMeasurement.characteristics &&
                                    Object.keys(selectedMeasurement.characteristics)?.length &&
                                    (selectedMeasurement.movement.id === 'maxsprint' ||
                                      selectedMeasurement.movement.id === 'sprintnostartblock') && (
                                      <MaxSprintCharacteristics
                                        measurementId={selectedMeasurement.id}
                                        characteristics={selectedMeasurement.characteristics}
                                      />
                                    )}
                                  {selectedMeasurement.characteristics &&
                                    Object.keys(selectedMeasurement.characteristics)?.length &&
                                    selectedMeasurement.movement.id === 'longjump' && (
                                      <LongJumpCharacteristics
                                        measurementId={selectedMeasurement.id}
                                        characteristics={selectedMeasurement.characteristics}
                                      />
                                    )}
                                </div>
                              )}
                              <div style={{ marginBottom: '4em', textAlign: 'center' }}>
                                <AclControl writePermissions={[reAnalyzePermission, invalidatePermission]}>
                                  <div
                                    style={{
                                      marginTop: '2em',
                                      paddingTop: '1em',
                                      borderTop: '1px solid #ddd',
                                      display: 'flex',
                                      justifyContent: 'space-around',
                                    }}
                                  >
                                    {rawFileId && !selectedMeasurement.invalid && (
                                      <AclControl writePermissions={[reAnalyzePermission]}>
                                        <Mutation
                                          mutation={reAnalyzeMeasurementMutation}
                                          variables={{ id: selectedMeasurement.id }}
                                          onCompleted={data => {
                                            showSuccessToast(`${_(t.measurementIsBeingReAnalyzed)}...`)
                                          }}
                                          onError={err => {
                                            showErrorToast(_(t.errorOnReAnalysis))
                                          }}
                                        >
                                          {(postMutation, { loading }) => (
                                            <SbButton
                                              icon="sbi-replace"
                                              loading={
                                                loading ||
                                                isQueued(selectedMeasurement.id) ||
                                                isRunning(selectedMeasurement.id)
                                              }
                                              disabled={
                                                loading ||
                                                isQueued(selectedMeasurement.id) ||
                                                isRunning(selectedMeasurement.id)
                                              }
                                              onClick={() => {
                                                postMutation()
                                              }}
                                            >
                                              {_(t.reAnalyzeMeasurement)}
                                            </SbButton>
                                          )}
                                        </Mutation>
                                      </AclControl>
                                    )}
                                    <AclControl writePermissions={[invalidatePermission]}>
                                      <Mutation
                                        mutation={toggleMeasurementInvalidationMutation}
                                        variables={{ id: selectedMeasurement.id }}
                                        refetchQueries={['findMeasurements']}
                                        onCompleted={data => {
                                          showSuccessToast(
                                            data?.toggleMeasurementInvalidation?.invalid
                                              ? _(t.movedToTrash)
                                              : _(t.recovered),
                                          )
                                        }}
                                        onError={err => {
                                          showErrorToast(
                                            selectedMeasurement.invalid ? _(t.errorOnRecovery) : _(t.errorOnTrashing),
                                          )
                                        }}
                                      >
                                        {(postMutation, { loading }) => (
                                          <SbButton
                                            icon={selectedMeasurement.invalid ? 'sbi-factory-reset' : 'sbi-trash'}
                                            className={selectedMeasurement.invalid ? 'success' : 'danger'}
                                            loading={
                                              loading ||
                                              isQueued(selectedMeasurement.id) ||
                                              isRunning(selectedMeasurement.id)
                                            }
                                            disabled={
                                              loading ||
                                              isQueued(selectedMeasurement.id) ||
                                              isRunning(selectedMeasurement.id)
                                            }
                                            onClick={() => {
                                              postMutation()
                                            }}
                                          >
                                            {selectedMeasurement.invalid ? _(t.recoverFromTrash) : _(t.moveToTrash)}
                                          </SbButton>
                                        )}
                                      </Mutation>
                                    </AclControl>
                                  </div>
                                </AclControl>
                              </div>
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )
                    }}
                  </Query>
                )}
              </div>
            </div>
          </React.Fragment>
        </div>
      </SbFullLayout>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  showSuccessToast: (params, config = {}) => showSuccessToast(params, config)(dispatch),
  showErrorToast: (params, config = {}) => showErrorToast(params, config)(dispatch),
  newSessionModal: params => dispatch(openModal(NEW_SESSION_MODAL_ID, params)),
  editParametersModal: params => dispatch(openModal(EDIT_SESSION_PARAMETERS_MODAL_ID, params)),
})

export const MeasurementsPage = injectSbLocale(
  injectIntl(connect(null, mapDispatchToProps)(withAclList(_MeasurementsPage, [readOwnMeasurementsPermission]))),
)
