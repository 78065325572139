import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { SbLeftColumn } from 'skybase-ui/skybase-components/sb-left-column/sb-left-column'

import { DEFAULT_IS_COLLAPSED } from './constants'
import { getIsCollapsed, getLocalStateForLeftColumn } from './selectors'

/**
 * @description
 * Simple wrapper for SbLeftColumn that normalizes props and connects to Redux.
 *
 * @param children      {React.Children}
 * @param isCollapsed   {Boolean}
 *
 * @returns {XML}
 */
const LeftColumn = ({ children, isCollapsed }) => {
  return <SbLeftColumn isCollapsed={ isCollapsed }>{children}</SbLeftColumn>
}

LeftColumn.propTypes = {
  children: PropTypes.node,
  isCollapsed: PropTypes.bool,
}

LeftColumn.defaultProps = {
  children: null,
  isCollapsed: DEFAULT_IS_COLLAPSED,
}

const mapStateToProps = state => ({
  isCollapsed: getIsCollapsed(getLocalStateForLeftColumn(state)),
})

export default connect(mapStateToProps)(LeftColumn)
