import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cancel: {
    id: 'create-user.cancel',
    defaultMessage: 'Cancel',
  },
  create: {
    id: 'create-user.create',
    defaultMessage: 'Create',
  },
  firstName: {
    id: 'create-user.firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'create-user.lastName',
    defaultMessage: 'Last Name'
  },
  email: {
    id: 'create-user.email',
    defaultMessage: 'Email'
  },
  type: {
    id: 'create-user.type',
    defaultMessage: 'Type'
  },
  canLogin: {
    id: 'create-user.canLogin',
    defaultMessage: 'Login'
  },
  enabled: {
    id: 'create-user.enabled',
    defaultMessage: 'Enabled'
  },
  disabled: {
    id: 'create-user.disabled',
    defaultMessage: 'Disabled'
  },
  isRequired: {
    id: 'create-user.isRequired',
    defaultMessage: 'is required'
  },
  invalidEmail: {
    id: 'create-user.invalidEmail',
    defaultMessage: 'Invalid Email Address'
  },
  tutor: {
    id: 'create-user.tutor',
    defaultMessage: 'Tutor'
  },
  athlete: {
    id: 'create-user.athlete',
    defaultMessage: 'Athlete'
  },
  sex: {
    id: 'create-user.sex',
    defaultMessage: 'Sex'
  },
  male: {
    id: 'create-user.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'create-user.female',
    defaultMessage: 'Female'
  },
  birthdate: {
    id: 'create-user.birthdate',
    defaultMessage: 'Date of Birth'
  },
  tutors: {
    id: 'create-user.tutors',
    defaultMessage: 'Tutors',
  },
  creationSuccessful: {
    id: 'create-user.creationSuccessful',
    defaultMessage: 'Successfully created {name}'
  },
  errorOnCreation: {
    id: 'create-user.errorOnCreation',
    defaultMessage: 'Error when attempting to create {name}'
  },
})
