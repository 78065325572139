/**
 * @description
 * Tells if left column will be displayed or not (by default).
 *
 * @type {boolean}
 */
export const DEFAULT_IS_COLLAPSED = false

/**
 * @desc
 * Defines a property name that will be used in the redux state.
 *
 * @type {string}
 */
export const STATE_KEY_NAME = 'leftColumn'
