import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import config from '@/config'

import {
  SbLabel,
  SbTextbox,
  SbButtonBar,
  SbRadioButton,
  SbButton,
  SbDateTime,
  SbMultiselect,
} from 'skybase-ui/skybase-components'

import { Query, Mutation } from '@apollo/react-components'
import { allTutorsQuery, createTutorMutation, createAthleteMutation } from './create-user-queries'

import { withAclList } from 'skybase-oauth/auth/with-acl-list'

import { isEmail } from '@/utils'

import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { messages as t } from './create-user-i18n'
import { athletesCreatePermission, tutorsCreatePermission } from './create-user-permissions'

class _CreateUserModal extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    refetchQueries: PropTypes.array,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    close: PropTypes.func.isRequired,
    aclList: PropTypes.shape({
      [athletesCreatePermission]: PropTypes.bool,
      [tutorsCreatePermission]: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {
    onSuccess: () => {},
    onError: () => {},
    refetchQueries: [],
  }

  constructor(props) {
    super(props)

    // Check if only Tutor or Athlete creation is permitted
    let type = undefined
    if (!props.aclList[athletesCreatePermission]) type = 'Tutor'
    else if (!props.aclList[tutorsCreatePermission]) type = 'Athlete'

    this.state = {
      firstName: null,
      lastName: null,
      type,
      withAccount: undefined,
      email: null,
      female: undefined,
      birthdate: new Date(new Date().setFullYear(new Date().getFullYear() - 20)),
      tutors: [],
      validated: false,
    }
  }

  validate() {
    const { firstName, lastName, withAccount, email, type, female, birthdate } = this.state
    this.setState({ validated: true })

    return (
      firstName?.length &&
      lastName?.length &&
      ['Athlete', 'Tutor'].includes(type) &&
      (!withAccount || (email?.length && isEmail(email))) &&
      (type !== 'Athlete' || (female !== undefined && birthdate !== undefined))
    )
  }

  onSuccess() {
    const { onSuccess, close } = this.props
    const { firstName, lastName } = this.state
    onSuccess(<FormattedMessage {...t.creationSuccessful} values={{ name: `${firstName} ${lastName}` }} />)
    close()
  }

  onError() {
    const { onError, close } = this.props
    const { firstName, lastName } = this.state
    onError(<FormattedMessage {...t.errorOnCreation} values={{ name: `${firstName} ${lastName}` }} />)
    close()
  }

  renderFooter = () => {
    const {
      intl: { formatMessage: _ },
      close,
      refetchQueries,
    } = this.props

    const { firstName, lastName, type, withAccount, email, female, birthdate, tutors } = this.state

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">
          <SbButton onClick={close}>{_(t.cancel)}</SbButton>
        </div>
        <div className="sb-modal-center"></div>
        <div className="sb-modal-right">
          <Mutation
            mutation={createTutorMutation}
            refetchQueries={refetchQueries}
            onCompleted={() => this.onSuccess()}
            onError={() => this.onError()}
          >
            {(postTutorMutation, { loading: tutorMutationLoading }) => (
              <Mutation
                mutation={createAthleteMutation}
                refetchQueries={refetchQueries}
                onCompleted={() => this.onSuccess()}
                onError={() => this.onError()}
              >
                {(postAthleteMutation, { loading: athleteMutationLoading }) => (
                  <SbButton
                    className={'primary'}
                    loading={tutorMutationLoading || athleteMutationLoading}
                    disabled={tutorMutationLoading || athleteMutationLoading}
                    onClick={() => {
                      if (this.validate()) {
                        switch (type) {
                          case 'Tutor':
                            postTutorMutation({
                              variables: { firstName, lastName, email },
                            })
                            break
                          case 'Athlete':
                            postAthleteMutation({
                              variables: {
                                firstName,
                                lastName,
                                female,
                                birthdate: new Date(birthdate.setHours(0, 0, 0, 0)),
                                tutorIds: tutors || [],
                                email: withAccount ? email : undefined,
                              },
                            })
                            break
                          default:
                            break
                        }
                      }
                    }}
                  >
                    {_(t.create)}
                  </SbButton>
                )}
              </Mutation>
            )}
          </Mutation>
        </div>
      </div>
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      aclList,
    } = this.props

    const { firstName, lastName, type, withAccount, email, female, birthdate, tutors, validated } = this.state

    return (
      <SbModal Header={<div />} Footer={this.renderFooter()}>
        <SbLabel
          title={_(t.firstName)}
          hasError={validated && !Boolean(firstName)}
          errorMessage={`${_(t.firstName)} ${_(t.isRequired)}`}
          required
        >
          <SbTextbox
            value={firstName || ''}
            placeholder={_(t.firstName)}
            hasError={validated && !Boolean(firstName)}
            autoFocus
            onChange={e => {
              this.setState({ firstName: e.target.value })
            }}
          />
        </SbLabel>
        <SbLabel
          title={_(t.lastName)}
          hasError={validated && !Boolean(lastName)}
          errorMessage={`${_(t.lastName)} ${_(t.isRequired)}`}
          required
        >
          <SbTextbox
            value={lastName || ''}
            placeholder={_(t.lastName)}
            hasError={validated && !Boolean(lastName)}
            onChange={e => {
              this.setState({ lastName: e.target.value })
            }}
          />
        </SbLabel>
        <SbLabel
          title={_(t.type)}
          required
          hasError={validated && !type}
          errorMessage={`${_(t.type)} ${_(t.isRequired)}`}
        >
          <SbButtonBar disabled={!(aclList[athletesCreatePermission] && aclList[tutorsCreatePermission])}>
            <SbRadioButton
              checked={type === 'Tutor'}
              onChange={() => {
                this.setState({ type: 'Tutor', withAccount: true })
              }}
            >
              {_(t.tutor)}
            </SbRadioButton>
            <SbRadioButton
              checked={type === 'Athlete'}
              onChange={() => {
                this.setState({ type: 'Athlete', withAccount: false })
              }}
            >
              {_(t.athlete)}
            </SbRadioButton>
          </SbButtonBar>
        </SbLabel>
        {type === 'Athlete' && (
          <SbLabel title={_(t.canLogin)} required>
            <SbButtonBar disabled={config.athleteLoginDisabled}>
              <SbRadioButton
                checked={withAccount}
                onChange={() => {
                  this.setState({ withAccount: true })
                }}
              >
                {_(t.enabled)}
              </SbRadioButton>
              <SbRadioButton
                checked={!withAccount}
                onChange={() => {
                  this.setState({ withAccount: false })
                }}
              >
                {_(t.disabled)}
              </SbRadioButton>
            </SbButtonBar>
          </SbLabel>
        )}
        {withAccount === true && (
          <SbLabel
            title={_(t.email)}
            required={true}
            errorMessage={!Boolean(email) ? `Email ${_(t.isRequired)}` : _(t.invalidEmail)}
            hasError={validated && (!Boolean(email) || !isEmail(email))}
          >
            <SbTextbox
              hasError={validated && (!Boolean(email) || !isEmail(email))}
              value={email || ''}
              placeholder={'Email'}
              onChange={e => {
                this.setState({ email: e.target.value })
              }}
            />
          </SbLabel>
        )}
        {type === 'Athlete' && (
          <React.Fragment>
            <SbLabel
              title={_(t.sex)}
              required
              hasError={validated && female === undefined}
              errorMessage={`${_(t.sex)} ${_(t.isRequired)}`}
            >
              <SbButtonBar>
                <SbRadioButton
                  checked={female}
                  onChange={() => {
                    this.setState({ female: true })
                  }}
                >
                  {_(t.female)}
                </SbRadioButton>
                <SbRadioButton
                  checked={female === false}
                  onChange={() => {
                    this.setState({ female: false })
                  }}
                >
                  {_(t.male)}
                </SbRadioButton>
              </SbButtonBar>
            </SbLabel>
            <SbLabel
              title={_(t.birthdate)}
              required
              errorMessage={`${_(t.birthdate)} ${_(t.isRequired)}`}
              hasError={validated && !Boolean(birthdate)}
            >
              <SbDateTime
                type={'date'}
                value={birthdate}
                hasError={validated && !Boolean(birthdate)}
                onChange={chosen => {
                  this.setState({ birthdate: chosen.value })
                }}
                position={'top-left'}
              />
            </SbLabel>
            <SbLabel title={_(t.tutors)}>
              <Query query={allTutorsQuery}>
                {({ loading, data }) => {
                  if (loading) return ''
                  return (
                    <SbMultiselect
                      selected={tutors}
                      items={data.allTutors.map(t => ({ value: t.id, label: `${t.firstName} ${t.lastName}` }))}
                      maxHeight={150}
                      onChange={chosen => {
                        this.setState({ tutors: chosen })
                      }}
                    />
                  )
                }}
              </Query>
            </SbLabel>
          </React.Fragment>
        )}
      </SbModal>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
})

export const CreateUserModal = injectIntl(
  connect(null, mapDispatchToProps)(withAclList(_CreateUserModal, [tutorsCreatePermission, athletesCreatePermission])),
)
