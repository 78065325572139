import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'measurements-page.pageName',
    defaultMessage: 'Measurements',
  },
  dateTime: {
    id: 'measurements-page.dateTime',
    defaultMessage: 'Date/Time',
  },
  athlete: {
    id: 'measurements-page.athlete',
    defaultMessage: 'Athlete',
  },
  tutor: {
    id: 'measurements-page.tutor',
    defaultMessage: 'Tutor',
  },
  movement: {
    id: 'measurements-page.movement',
    defaultMessage: 'Movement',
  },
  previous: {
    id: 'measurements-page.previous',
    defaultMessage: 'previous',
  },
  next: {
    id: 'measurements-page.next',
    defaultMessage: 'next',
  },
  showRecords: {
    id: 'measurements-page.showRecords',
    defaultMessage: 'Show records',
  },
  chooseAthletes: {
    id: 'measurements-page.chooseAthletes',
    defaultMessage: 'Choose Athletes',
  },
  chooseMovements: {
    id: 'measurements-page.chooseMovements',
    defaultMessage: 'Movements',
  },
  showAllMeasurements: {
    id: 'measurements-page.showAllMeasurements',
    defaultMessage: 'Show all',
  },
  noMeasurementsFound: {
    id: 'measurements-page.noMeasurementsFound',
    defaultMessage: 'No Measurements Found',
  },
  noMeasurementsForSession: {
    id: 'measurements-page.noMeasurementsForSession',
    defaultMessage: 'No Measurements for Current Session',
  },
  aSessionIsRunning: {
    id: 'measurements-page.aSessionIsRunning',
    defaultMessage: '{tutor} is currently running a {movement} session with {athletes}',
  },
  sessionIsRunningAthlete: {
    id: 'measurements-page.sessionIsRunningAthlete',
    defaultMessage: 'athlete',
  },
  sessionIsRunningAthletes: {
    id: 'measurements-page.sessionIsRunningAthletes',
    defaultMessage: 'athletes',
  },
  enableLiveMode: {
    id: 'measurements-page.enableLiveMode',
    defaultMessage: 'Enable Live Mode',
  },
  terminateSession: {
    id: 'measurements-page.terminateSession',
    defaultMessage: 'Terminate Session',
  },
  successfulTermination: {
    id: 'measurements-page.successfulTermination',
    defaultMessage: 'The session has been terminated successfully',
  },
  errorOnTermination: {
    id: 'measurements-page.errorOnTermination',
    defaultMessage: 'An error occurred when terminating the session',
  },
  measurementDetails: {
    id: 'measurements-page.measurementDetails',
    defaultMessage: 'Measurement Details',
  },
  step: {
    id: 'measurements-page.step',
    defaultMessage: 'Step',
  },
  characteristicsLoading: {
    id: 'measurements-page.characteristicsLoading',
    defaultMessage: 'Analysis in progress...',
  },
  report: {
    id: 'measurements-page.report',
    defaultMessage: 'Report',
  },
  shortReport: {
    id: 'measurements-page.shortReport',
    defaultMessage: 'Short Report',
  },
  rawData: {
    id: 'measurements-page.rawData',
    defaultMessage: 'Raw Data',
  },
  results: {
    id: 'measurements-page.results',
    defaultMessage: 'Results',
  },
  download: {
    id: 'measurements-page.download',
    defaultMessage: 'Download',
  },
  parameters: {
    id: 'measurements-page.parameters',
    defaultMessage: 'Parameters',
  },
  weight: {
    id: 'measurements-page.weight',
    defaultMessage: 'Weight',
  },
  height: {
    id: 'measurements-page.height',
    defaultMessage: 'Height',
  },
  frontAngle: {
    id: 'measurements-page.frontAngle',
    defaultMessage: 'Front Angle',
  },
  rearAngle: {
    id: 'measurements-page.rearAngle',
    defaultMessage: 'Rear Angle',
  },
  frontDistance: {
    id: 'measurements-page.frontDistance',
    defaultMessage: 'Front Distance',
  },
  rearDistance: {
    id: 'measurements-page.rearDistance',
    defaultMessage: 'Rear Distance',
  },
  hurdleHeight: {
    id: 'measurements-page.hurdleHeight',
    defaultMessage: 'Hurdle Height',
  },
  newSession: {
    id: 'measurements-page.newSession',
    defaultMessage: 'New Session',
  },
  noMeasurementDataFound: {
    id: 'measurements-page.noMeasurementDataFound',
    defaultMessage: 'No Measurement Data',
  },
  Velocity: {
    id: 'measuremets-page.Velocity',
    defaultMessage: 'Velocity',
  },
  VelocityChange: {
    id: 'measurements-page.VelocityChange',
    defaultMessage: 'Velocity Change',
  },
  MeanVelocityOverSteps: {
    id: 'measurements-page.MeanVelocityOverSteps',
    defaultMessage: 'Mean Velocity over Steps',
  },
  MeanVelocityOverWayTime: {
    id: 'measurements-page.MeanVelocityOverWayTime',
    defaultMessage: 'Mean Velocity Total Way / Total Time',
  },
  MaxVelocity: {
    id: 'measurements-page.MaxVelocity',
    defaultMessage: 'Maximal Velocity',
  },
  StepFrequency: {
    id: 'measurements-page.StepFrequency',
    defaultMessage: 'Step Frequency',
  },
  MeanStepFrequency: {
    id: 'measurements-page.MeanStepFrequency',
    defaultMessage: 'Mean Step Frequency',
  },
  StepLength: {
    id: 'measurements-page.StepLength',
    defaultMessage: 'Step Length',
  },
  MeanStepLength: {
    id: 'measurements-page.MeanStepLength',
    defaultMessage: 'Mean Step Length',
  },
  GroundContactTime: {
    id: 'measurements-page.AroundContactTime',
    defaultMessage: 'Ground Contact Time',
  },
  BrakingImpulseDuration: {
    id: 'measurements-page.BrakingImpulseDuration',
    defaultMessage: 'Braking Impulse Duration',
  },
  AccelerationImpulseDuration: {
    id: 'measurements-page.AccelerationImpulseDuration',
    defaultMessage: 'Acceleration Impulse Duration',
  },
  GroupAirTimeSteps: {
    id: 'measurements-page.groupAirTimeSteps',
    defaultMessage: 'Air Time',
  },
  GroundReactionTimeToAirTime: {
    id: 'measurements-page.GroundReactionTimeToAirTime',
    defaultMessage: 'Quotient of Ground Reaction Time to Air Time',
  },
  WeightedAccelerationImpulse: {
    id: 'measurements-page.weightedAccelerationImpulse',
    defaultMessage: 'Weighted Acceleration Impulse',
  },
  WeightedBrakingImpulse: {
    id: 'measurements-page.weightedBreakingImpulse',
    defaultMessage: 'Weighted Braking Impulse',
  },
  WeightedAccelerationToBrakingImpulse: {
    id: 'measurements-page.weightedAccelerationToBreakingImpulse',
    defaultMessage: 'Weigthed Acceleration to Braking Impulse',
  },
  WeightedVerticalImpulse: {
    id: 'measurements-page.WeightedVerticalImpulse',
    defaultMessage: 'Weighted Vertical Impulse',
  },
  WeightedLateralImpulse: {
    id: 'measurements-page.WeightedLateralImpulse',
    defaultMessage: 'Weighted Lateral Impulse',
  },
  WeightedMedialImpulse: {
    id: 'measurements-page.WeightedMedialImpulse',
    defaultMessage: 'Weighted Medial Impulse',
  },
  WeightedLateralToMedialImpulse: {
    id: 'measurements-page.WeightedLateralToMedialImpulse',
    defaultMessage: 'Difference of Weighted Lateral to Medial Impulse',
  },
  Fz: {
    id: 'measurements-page.Fz',
    defaultMessage: 'Force Vertical'
  },
  Fy: {
    id: 'measurements-page.Fy',
    defaultMessage: 'Force in Direction of Movement'
  },
  Fx: {
    id: 'measurements-page.Fx',
    defaultMessage: 'Force Left - Right'
  },
  FzLast3Plates: {
    id: 'measurements-page.FzLast3Plates',
    defaultMessage: 'Force Vertical'
  },
  FyLast3Plates: {
    id: 'measurements-page.FyLast3Plates',
    defaultMessage: 'Force in Direction of Movement'
  },
  FxLast3Plates: {
    id: 'measurements-page.FxLast3Plates',
    defaultMessage: 'Force Left - Right'
  },
  FyAcceleration: {
    id: 'measurements-page.FyAcceleration',
    defaultMessage: 'Fy Acceleration'
  },
  FzAcceleration: {
    id: 'measurements-page.FzAcceleration',
    defaultMessage: 'Fz Acceleration'
  },
  FyVelocity: {
    id: 'measurements-page.FyVelocity',
    defaultMessage: 'Fy Velocity'
  },
  FzVelocity: {
    id: 'measurements-page.FzVelocity',
    defaultMessage: 'Fz Velocity'
  },
  last3Plates: {
    id: 'measurements-page.last3Plates',
    defaultMessage: 'Last 3 Plates'
  },
  jumpAngle: {
    id: 'measurements-page.jumpAngle',
    defaultMessage: 'Jump Angle'
  },
  AccelerationOverTime: {
    id: 'measurements-page.accelerationOverTime',
    defaultMessage: 'Acceleration over Time'
  },
  VelocityOverTime: {
    id: 'measurements-page.velocityOverTime',
    defaultMessage: 'Velocity over Time'
  },
  MaxForceRearTotal: {
    id: 'measurements-page.maxForceRearTotal',
    defaultMessage: 'Max F - rear - total'
  },
  MaxForceFrontTotal: {
    id: 'measurements-page.maxForceFrontTotal',
    defaultMessage: 'Max F - front - total'
  },
  MaxForceHandsTotal: {
    id: 'measurements-page.maxForceHandsTotal',
    defaultMessage: 'Max F - hands - total'
  },
  MaxForceCOMTotal: {
    id: 'measurements-page.maxForceCOMTotal',
    defaultMessage: 'Max F - COM - total'
  },
  MaxRFDRearTotal: {
    id: 'measurements-page.maxRFDRearTotal',
    defaultMessage: 'Max RFD - rear - total'
  },
  MaxRFDFrontTotal: {
    id: 'measurements-page.maxRFDFrontTotal',
    defaultMessage: 'Max RFD - front - total'
  },
  MaxRFDHandsTotal: {
    id: 'measurements-page.maxRFDHandsTotal',
    defaultMessage: 'Max RFD - hands - total'
  },
  MaxRFDCOMTotal: {
    id: 'measurements-page.maxRFDCOMTotal',
    defaultMessage: 'Max RFD - COM - total'
  },
  AngleOfForceVectorAtToRearBlockFront: {
    id: 'measurements-page.AngleOfForceVectorAtToRearlockFront',
    defaultMessage: 'Angle of force vector at TO rear block - front'
  },
  AngleOfForceVectorAtToRearBlockCOM: {
    id: 'measurements-page.AngleOfForceVectorAtToRearBlockCOM',
    defaultMessage: 'Angle of force vector at TO rear block - COM'
  },
  AngleOfForceVectorAtToHandsRear: {
    id: 'measurements-page.AngleOfForceVectorAtToHandsRear',
    defaultMessage: 'Angle of force vector at TO hands - rear'
  },
  AngleOfForceVectorAtToHandsFront: {
    id: 'measurements-page.AngleOfForceVectorAtToHandsFront',
    defaultMessage: 'Angle of force vector at TO hands - front'
  },
  AngleOfForceVectorAtToHandsCOM: {
    id: 'measurements-page.AngleOfForceVectorAtToHandsCOM',
    defaultMessage: 'Angle of force vector at TO hands - COM'
  },
  ReactionTime: {
    id: 'measurements-page.ReactionTime',
    defaultMessage: 'Reaction Time'
  },
  BlockTimeCOM: {
    id: 'measurements-page.BlockTimeCOM',
    defaultMessage: 'Block time - COM'
  },
  PushTimeRear: {
    id: 'measurements-page.PushTimeRear',
    defaultMessage: 'Push time - rear'
  },
  PushTimeFront: {
    id: 'measurements-page.PushTimeFront',
    defaultMessage: 'Push time - front'
  },
  PushTimeHands: {
    id: 'measurements-page.PushTimeHands',
    defaultMessage: 'Push time - hands'
  },
  PushTimeCOM: {
    id: 'measurements-page.PushTimeCOM',
    defaultMessage: 'Push time - COM'
  },
  averagePowerHorizontal: {
    id: 'measurements-page.averagePowerHorizontal',
    defaultMessage: 'Average power - horizontal'
  },
  ChartAveragePowerHorizontal1: {
    id: 'measurements-page.ChartAveragePowerHorizontal1',
    defaultMessage: 'Average P - rear - horizontal'
  },
  ChartAveragePowerHorizontal2: {
    id: 'measurements-page.ChartAveragePowerHorizontal2',
    defaultMessage: 'Average P - front - horizontal'
  },
  averageForceHorizontal: {
    id: 'measurements-page.averageForceHorizontal',
    defaultMessage: 'Average force - horizontal'
  },
  ChartAverageForceHorizontal1: {
    id: 'measurements-page.ChartAverageForceHorizontal1',
    defaultMessage: 'Average F - rear - horizontal'
  },
  ChartAverageForceHorizontal2: {
    id: 'measurements-page.ChartAverageForceHorizontal2',
    defaultMessage: 'Average F - front - horizontal'
  },
  velocityHorizontal: {
    id: 'measurements-page.velocityHorizontal',
    defaultMessage: 'Velocity - horizontal'
  },
  ChartVelocityHorizontal1: {
    id: 'measurements-page.ChartVelocityHorizontal1',
    defaultMessage: 'V - rear - horizontal'
  },
  ChartVelocityHorizontal2: {
    id: 'measurements-page.ChartVelocityHorizontal2',
    defaultMessage: 'V - front - horizontal'
  },
  maximalRfdHorizontal: {
    id: 'measurements-page.maximalRfdHorizontal',
    defaultMessage: 'Maximal RFD - horizontal'
  },
  ChartMaximalRFDHorizontal1: {
    id: 'measurements-page.ChartMaximalRFDHorizontal1',
    defaultMessage: 'Max RFD - rear - horizontal'
  },
  ChartMaximalRFDHorizontal2: {
    id: 'measurements-page.ChartMaximalRFDHorizontal2',
    defaultMessage: 'Max RFD - front - horizontal'
  },
  averageRfdHorizontal: {
    id: 'measurements-page.averageRfdHorizontal',
    defaultMessage: 'Average RFD - horizontal'
  },
  ChartAverageRFDHorizontal1: {
    id: 'measurements-page.ChartAverageRFDHorizontal1',
    defaultMessage: 'Average RFD - rear - horizontal'
  },
  ChartAverageRFDHorizontal2: {
    id: 'measurements-page.ChartAverageRFDHorizontal2',
    defaultMessage: 'Average RFD - front - horizontal'
  },
  times: {
    id: 'measurements-page.times',
    defaultMessage: 'Times'
  },
  ChartTime1: {
    id: 'measurements-page.ChartTime1',
    defaultMessage: 'Reaction time'
  },
  ChartTime2: {
    id: 'measurements-page.ChartTime2',
    defaultMessage: 'Time to max P - COM - horizontal'
  },
  ChartTime3: {
    id: 'measurements-page.ChartTime3',
    defaultMessage: 'Block time - COM'
  },
  stepParameters: {
    id: 'measurements-page.stepParameters',
    defaultMessage: 'Step Parameters',
  },
  startBlockParameters: {
    id: 'measurements-page.startBlockParameters',
    defaultMessage: 'Start Block Parameters',
  },
  cancel: {
    id: 'measurements-page.cancel',
    defaultMessage: 'Cancel',
  },
  back: {
    id: 'measurements-page.back',
    defaultMessage: 'Back',
  },
  start: {
    id: 'measurements-page.start',
    defaultMessage: 'Start',
  },
  chooseMovement: {
    id: 'measurements-page.chooseMovement',
    defaultMessage: 'Please choose the movement to execute',
  },
  chooseAthletesForSession: {
    id: 'measurements-page.chooseAthletesForSession',
    defaultMessage: 'Please choose the Athletes to include in this session',
  },
  name: {
    id: 'measurements-page.name',
    defaultMessage: 'Name',
  },
  enterMetadata: {
    id: 'measurements-page.enterMetadata',
    defaultMessage: 'Please enter the required Athlete metadata for this measurement session',
  },
  female: {
    id: 'measurements-page.female',
    defaultMessage: 'female',
  },
  male: {
    id: 'measurements-page.male',
    defaultMessage: 'male',
  },
  birthdate: {
    id: 'measurements-page.birthdate',
    defaultMessage: 'Date of Birth',
  },
  sex: {
    id: 'measurements-page.sex',
    defaultMessage: 'Sex',
  },
  sessionStarted: {
    id: 'measurements-page.sessionStarted',
    defaultMessage: 'The session has been started successfully',
  },
  errorOnStart: {
    id: 'measurements-page.errorOnStart',
    defaultMessage: 'An error occurred when starting the session',
  },
  editParameters: {
    id: 'measurements-page.editParameters',
    defaultMessage: 'Edit Parameters',
  },
  updateSessionParameters: {
    id: 'measurements-page.updateSessionParameters',
    defaultMessage: 'Update Session Parameters',
  },
  parametersUpdated: {
    id: 'measurements-page.parametersUpdated',
    defaultMessage: 'Parameters have been updated successfully',
  },
  errorOnUpdate: {
    id: 'measurements-page.errorOnUpdate',
    defaultMessage: 'An error occurred when updating the parameters',
  },
  measurementIsBeingReAnalyzed: {
    id: 'measurements-page.measurementIsBeingReAnalyzed',
    defaultMessage: 'The measurement is being re-analyzed'
  },
  errorOnReAnalysis: {
    id: 'measurements-page.errorOnReAnalysis',
    defaultMessage: 'An error occurred when attempting to re-analyze the measurement'
  },
  reAnalyzeMeasurement: {
    id: 'measurements-page.reAnalyzeMeasurement',
    defaultMessage: 'Re-Analyze the Measurement'
  },
  reAnalyzeNow: {
    id: 'measurements-page.reAnalyzeNow',
    defaultMessage: 'Re-Analyze Now'
  },
  measurementHasBeenReAssigned: {
    id: 'measurements-page.measurementHasBeenReAssigned',
    defaultMessage: 'The measurement has been re-assigned successfully'
  },
  errorOnReAssignment: {
    id: 'measurements-page.errorOnReAssignment',
    defaultMessage: 'An error occurred when attempting to re-assign the measurement'
  },
  time: {
    id: 'measurements-page.time',
    defaultMessage: 'Time'
  },
  analysisIsQueued: {
    id: 'measurements-page.analysisIsQueued',
    defaultMessage: 'Waiting for analysis to start'
  },
  noCharacteristics: {
    id: 'measurements-page.noCharacteristics',
    defaultMessage: 'No Characteristics for this Measurement'
  },
  moveToTrash: {
    id: 'measurements-page.moveToTrash',
    defaultMessage: 'Move measurement to trash'
  },
  recoverFromTrash: {
    id: 'measurements-page.recoverFromTrash',
    defaultMessage: 'Recover from trash'
  },
  movedToTrash: {
    id: 'measurements-page.movedToTrash',
    defaultMessage: 'The measurement has been moved to trash successfully'
  },
  errorOnTrashing: {
    id: 'measurements-page.errorOnTrashing',
    defaultMessage: 'An error occurred when attempting to trash the measurement'
  },
  recovered: {
    id: 'measurements-page.recovered',
    defaultMessage: 'The measurement has been recovered successfully',
  },
  errorOnRecovery: {
    id: 'measurements-page.errorOnRecovery',
    defaultMessage: 'An error occurred when attempting to recover the measurement'
  },
  show: {
    id: 'measurements-page.show',
    defaultMessage: 'show'
  },
  velocityAndStepFrequency: {
    id: 'measurements-page.velocityAndStepFrequency',
    defaultMessage: 'Velocity and Step Frequency'
  },
  accelerationAndBrakeImpulses: {
    id: 'measurements-page.accelerationAndBrakeImpulses',
    defaultMessage: 'Acceleration and Brake Impulses'
  },
  selectedDiagrams: {
    id: 'measurements-page.selectedDiagrams',
    defaultMessage: 'Selected Diagrams'
  },
  forceTimeSeries: {
    id: 'measurements-page.forceTimeSeries',
    defaultMessage: 'Force-Time Series'
  },
  DutyFactor: {
    id: 'measurements-page.DutyFactor',
    defaultMessage: 'Duty Factor'
  },
  ActivityIndex: {
    id: 'measurements-page.ActivityIndex',
    defaultMessage: 'Activity Index'
  },
  RelativeStepLength: {
    id: 'measurements-page.RelativeStepLength',
    defaultMessage: 'Relative Step Length'
  },
  comments: {
    id: 'measurements-page.Comment',
    defaultMessage: 'Comments'
  }
})
