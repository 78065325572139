import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { SbFullLayout, PageTabPane } from 'skybase-ui/skybase-components'
import { Licenses } from 'skybase-ui/widgets/licenses'

import { messages as t } from './licenses-page-i18n'

import './licenses-page.scss'

export class _LicensesPage extends PureComponent {
  static propTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    // If there aren't any licenses in this page, try to run the script "npm run generate-licenses".
    // That should create licenses in the folder public/static/licenses along with the license-manifest-ui.json file.
    // Licenses however should be generated during the build process.
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbFullLayout
        tabPane={<PageTabPane />}
        title={_(t.licenses)}
        className="license-page"
        breadcrumbs={[{ path: '/about', title: _(t.aboutPageName) }, _(t.pageName)]}
      >
        <div className="licenses">
          <h1>{_(t.licenses)}</h1>
          <div className="notice">{_(t.licensesCopyright)}</div>
          <Licenses />
        </div>
      </SbFullLayout>
    )
  }
}

export const LicensesPage = injectIntl(_LicensesPage)
