import { permissions as p } from '@/common/permissions'

export const usersPagePermissions = [
  p.ACCOUNTS_READ_ALL,
  p.ACCOUNTS_UPDATE_ALL,
  p.KICONNECT_USERS_WRITE,
]

export const deletePermission = p.ATHLETES_DELETE

export const tutorsCreatePermission = p.TUTORS_CREATE
export const athletesCreatePermission = p.ATHLETES_CREATE

export const athletesUpdatePermission = p.ATHLETES_UPDATE_ALL
