import { gql } from '@apollo/client'

const measurementFields = `
  id
  timestamp
  parameters
  characteristics
  athlete {
    id
    firstName
    lastName
  }
  measurer {
    id
    firstName
    lastName
  }
  movement {
    id
    name
  }
  files {
    id
    type
    cameraId
  }
  sessionId,
  invalid
  comments
`

export const assignedMeasurementAddedSubscription = gql`
  subscription {
    assignedMeasurementAdded {
      ${measurementFields}
    }
  }
`

export const assignedMeasurementUpdatedSubscription = gql`
  subscription {
    assignedMeasurementUpdated {
      ${measurementFields}
    }
  }
`

export const reAnalyzeMeasurementMutation = gql`
  mutation reAnalyzeMeasurement($id: ID!, $comments: String) {
    reAnalyzeMeasurement(id: $id, comments: $comments) {
      ${measurementFields}
    }
  }
`

export const modifyMeasurementCommentsMutation = gql`
  mutation modifyMeasurementComments($id: ID!, $comments: String) {
    modifyMeasurementComments(id: $id, comments: $comments) {
      ${measurementFields}
    }
  }
`

export const reAssignMeasurementMutation = gql`
  mutation reAssignMeasurement($id: ID!, $newAthleteId: ID!) {
    reAssignMeasurement(id: $id, newAthleteId: $newAthleteId) {
      ${measurementFields}
    }
  }
`

export const toggleMeasurementInvalidationMutation = gql`
  mutation toggleMeasurementInvalidation($id: ID!) {
    toggleMeasurementInvalidation(id: $id) {
      ${measurementFields}
    }
  }
`

export const measurementQuery = gql`
  query measurement($id: ID!) {
    measurement(id: $id) {
      ${measurementFields}
    }
  }
`

const currentSessionFields = `
  currentSession {
    id
    athletes
    movement {
      name,
      parameters
    }
    measurer {
      id
      accountId
      firstName
      lastName
    }
  }
`

export const currentSessionQuery = gql`
  query {
    ${currentSessionFields}
    myAccount {
      accountId
    }
  }
`

export const currentSessionSubscription = gql`
  subscription {
    ${currentSessionFields}
  }
`

export const resetSessionMutation = gql`
  mutation {
    resetSession {
      id
    }
  }
`

export const currentAnalysisStatusQuery = gql`
  query {
    analysisStatus {
      status
    }
  }
`
export const currentAnalysisStatusSubscription = gql`
  subscription {
    analysisStatus {
      status
    }
  }
`

export const filterDataQuery = gql`
  query {
    assignedAthletes {
      id
      firstName
      lastName
    }
    allMovements {
      id
      name
    }
  }
`

export const athleteFilterDataQuery = gql `
  query {
    allMovements {
      id
      name
    }
  }
`

export const findMeasurementsQuery = gql`
  query findMeasurements(
    $skip: Int!
    $take: Int!
    $dateFilter: DateTime
    $athleteFilter: [ID]
    $movementFilter: [String]
    $sessionId: ID,
    $includeInvalid: Boolean
  ) {
    findMeasurements(
      skip: $skip
      take: $take
      dateFilter: $dateFilter
      athleteFilter: $athleteFilter
      movementFilter: $movementFilter
      sessionId: $sessionId
      includeInvalid: $includeInvalid
    ) {
      skip
      take
      totalCount
      measurements {
        id
        timestamp
        athlete {
          id
          firstName
          lastName
        }
        measurer {
          id
        }
        movement {
          id
          name
        },
        invalid
      }
    }
  }
`

export const startSessionDataQuery = gql`
  query {
    assignedAthletes {
        id, firstName, lastName, birthdate, female, measurements { movement { id }, parameters, timestamp }
    },
    allMovements {
        id, name, enabled, parameters
    }
  }
`

export const newSessionMutation = gql`
  mutation newSession($movementId: ID!, $athleteIds: [ID]!, $athleteParameters: [JSONObject]!) {
    newSession(movementId: $movementId, athleteIds: $athleteIds, athleteParameters: $athleteParameters) {
      id
    }
  }
`

export const updateParametersMutation = gql`
  mutation updateSessionParameters($sessionId: ID!, $athleteIds: [ID]!, $athleteParameters: [JSONObject]!) {
    updateSessionParameters(sessionId: $sessionId, athleteIds: $athleteIds, athleteParameters: $athleteParameters) {
      id
    }
  }
`
