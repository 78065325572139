import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import { Query, Mutation } from '@apollo/react-components'

import { sbLocaleShape, injectSbLocale } from 'skybase-ui/skybase-components/sb-locale'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { SbLoader, SbMultiselect, SbDropdownButton } from 'skybase-ui/skybase-components'
import { showSuccessToast, showErrorToast } from 'skybase-ui/skybase-components/sb-toastr'

import { ParameterEditor } from '@/components/parameter-editor'

import { messages as t } from './measurements-page-i18n'
import { startSessionDataQuery, newSessionMutation } from './measurements-page-queries'

class _NewSessionModal extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    showErrorToast: PropTypes.func.isRequired,
    showSuccessToast: PropTypes.func.isRequired,
    sbLocale: sbLocaleShape.isRequired,
    close: PropTypes.func.isRequired,
    goToMeasurementsPage: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      movement: undefined,
      athletes: [],
      parameters: {},
      assignedAthletes: undefined,
    }
  }

  renderFooter = () => {
    const {
      intl: { formatMessage: _ },
      close,
      goToMeasurementsPage,
      showSuccessToast,
      showErrorToast,
    } = this.props

    const { step, athletes, parameters, movement, assignedAthletes } = this.state

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">
          <SbButton onClick={close}>{_(t.cancel)}</SbButton>
        </div>
        <div className="sb-modal-center"></div>
        <div className="sb-modal-right">
          {step === 1 && (
            <SbButton
              className={'primary'}
              onClick={() => {
                let params = {}
                athletes.forEach(aId => {
                  const athlete = assignedAthletes.find(a => a.id === aId)
                  if (athlete?.measurements?.length) {
                    const m = athlete.measurements?.find(m => m?.movement?.id === movement.id)
                    const ap = m?.parameters
                    if (ap) {
                      const oneWeekAgo = new Date().getTime() - 1000 * 60 * 60 * 24 * 7
                      const measurementTS = new Date(m.timestamp).getTime()
                      const youngerThanOneWeek = Boolean(measurementTS > oneWeekAgo)
                      movement.parameters.forEach(p => {
                        if ((p !== 'weight' || youngerThanOneWeek) && p in ap) {
                          if (!params[aId]) params[aId] = {}
                          params[aId][p] = ap[p]
                        }
                      })
                    }
                  }
                })
                this.setState({ step: 2, parameters: params })
              }}
              disabled={athletes.length === 0 || !movement}
            >
              {_(t.next) + ' >'}
            </SbButton>
          )}
          {step === 2 && (
            <React.Fragment>
              <SbButton
                style={{ marginRight: '1em' }}
                onClick={() => {
                  this.setState({ step: 1 })
                }}
              >
                {'< ' + _(t.back)}
              </SbButton>
              <Mutation
                mutation={newSessionMutation}
                onCompleted={({ session }) => {
                  showSuccessToast(_(t.sessionStarted))
                  goToMeasurementsPage()
                  close()
                }}
                onError={() => {
                  showErrorToast(_(t.errorOnStart))
                }}
              >
                {(postMutation, { loading }) => (
                  <SbButton
                    className={'primary'}
                    onClick={() => {
                      postMutation({
                        variables: {
                          movementId: movement.id,
                          athleteIds: athletes,
                          athleteParameters: athletes.map(a => ({
                            ...parameters[a],
                          })),
                        },
                      })
                    }}
                    disabled={
                      Object.keys(parameters).length !== athletes.length ||
                      !Object.keys(parameters).reduce(
                        (acc, id) =>
                          acc && parameters[id] && Object.keys(parameters[id]).length === movement.parameters.length,
                        true,
                      ) ||
                      loading
                    }
                    loading={loading}
                  >
                    {_(t.start)}
                  </SbButton>
                )}
              </Mutation>
            </React.Fragment>
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      sbLocale: { formatDate },
    } = this.props

    const { step, athletes, movement, assignedAthletes, parameters } = this.state

    return (
      <SbModal Header={<h1>{_(t.newSession)}</h1>} Footer={this.renderFooter()} width={1280} height={650}>
        <Query
          query={startSessionDataQuery}
          fetchPolicy={'no-cache'}
          onCompleted={data => {
            if (data?.assignedAthletes && !assignedAthletes) {
              this.setState({ assignedAthletes: data.assignedAthletes })
            }
          }}
        >
          {({ loading, data }) => {
            return (
              <React.Fragment>
                {loading && <SbLoader show />}
                {step === 1 && (
                  <React.Fragment>
                    <p>{_(t.chooseMovement)}:</p>
                    {!loading && (
                      <SbDropdownButton
                        size={'xl'}
                        menuPosition={'bottom-right'}
                        selected={movement?.id}
                        items={data.allMovements.map(m => ({
                          id: m.id,
                          value: m.id,
                          name: m.name,
                          style: { color: m.enabled ? 'black' : 'grey', cursor: m.enabled ? 'pointer' : 'not-allowed' },
                        }))}
                        onChange={chosen => {
                          const m = data.allMovements.find(m => m.id === chosen)
                          if (m && m.enabled) {
                            this.setState({ movement: m, parameters: {} })
                          }
                        }}
                      />
                    )}
                    <p>{_(t.chooseAthletesForSession)}:</p>
                    {!loading && (
                      <SbMultiselect
                        selected={athletes}
                        items={data.assignedAthletes.map(a => ({
                          value: a.id,
                          label: `${a.firstName} ${a.lastName} (${formatDate(new Date(a.birthdate))}, ${
                            a.female ? _(t.female) : _(t.male)
                          })`,
                        }))}
                        onChange={chosen => {
                          this.setState({ athletes: chosen })
                        }}
                        maxHeight={350}
                      />
                    )}
                  </React.Fragment>
                )}
                {step === 2 && (
                  <React.Fragment>
                    <p>{_(t.enterMetadata)}:</p>
                    <ParameterEditor
                      parameters={parameters}
                      requiredParameters={movement.parameters}
                      athletes={data?.assignedAthletes.filter(a => athletes.includes(a.id)) || []}
                      updateParameters={newParameters => {
                        this.setState({ parameters: newParameters })
                      }}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )
          }}
        </Query>
      </SbModal>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
  showSuccessToast: (params, config = {}) => showSuccessToast(params, config)(dispatch),
  showErrorToast: (params, config = {}) => showErrorToast(params, config)(dispatch),
  goToMeasurementsPage: () => dispatch(push('/measurements')),
})

export const NewSessionModal = injectIntl(injectSbLocale(connect(null, mapDispatchToProps)(_NewSessionModal)))
