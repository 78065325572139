import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { push } from 'connected-react-router'

import { Menu } from '@/components/menu'
import { Header } from '@/containers/header'
import { LeftColumn } from '@/containers/left-column'

import './app.scss'

class App extends PureComponent {
  render() {
    const { currentUrl, children, isReady, navigateTo } = this.props

    return (
      <div>
        <div className="app-wrapper fl-container-column">
          <Header />
          <div className="app-content-container fl-row">
            <LeftColumn>
              <Menu currentUrl={currentUrl} onMenuItemClick={navigateTo} />
            </LeftColumn>
            <div className="content-page-container">{isReady && children}</div>
          </div>
        </div>
      </div>
    )
  }
}

App.propTypes = {
  currentUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isReady: PropTypes.bool.isRequired,
  navigateTo: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
  navigateTo(url) {
    dispatch(push(url))
  },
})

const mapStateToProps = (state, props) => {
  const {
    location: { pathname },
  } = props

  return {
    currentUrl: pathname,
    isReady: true,
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
