import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { OAuth } from 'skybase-oauth/oauth'

// HTTP Link for Queries and Mutations
const apiLink = createHttpLink({
  uri: '/graphql',
})
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = OAuth.oAuthHandler.getAccessToken().accessToken
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// WebSocket Link for Subscriptions
const wsprot = (window.location.protocol === 'https:' ? 'wss' : 'ws')
const wsLink = new WebSocketLink({
  uri: `${wsprot}://${window.location.host}/subscriptions`,
  options: {
    reconnect: true,
    connectionParams: () => ({      
      authToken: OAuth.oAuthHandler.getAccessToken().accessToken,    
    })
  },
})

// Decide on which link to use
const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  authLink.concat(apiLink),
)

export const graphQlClient = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
})
