import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import { SbDropdownButton, SbTextbox, SbDataTable } from 'skybase-ui/skybase-components'
import { sbLocaleShape, injectSbLocale } from 'skybase-ui/skybase-components/sb-locale'

import { messages as t } from './parameter-editor-i18n'
import { parameterUnits as units } from './constants'

import './parameter-editor.scss'

class _ParameterEditor extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    sbLocale: sbLocaleShape.isRequired,
    updateParameters: PropTypes.func.isRequired,
    parameters: PropTypes.object.isRequired,
    requiredParameters: PropTypes.array.isRequired,
    athletes: PropTypes.array.isRequired,
  }

  cellFormatter = (value, key, row) => {
    const { parameters, updateParameters } = this.props

    switch (key) {
      case 'weight':
      case 'height':
      case 'frontAngle':
      case 'rearAngle':
      case 'frontDistance':
      case 'rearDistance':
      case 'hurdleDistance':
        return (
          <SbTextbox
            type={'number'}
            value={parameters[row.id] ? parameters[row.id][key] || null : null}
            suffix={units[key]}
            fraction={['weight', 'hurdleDistance'].includes(key) ? 2 : 0}
            onChange={(e, val) => {
              let p = { ...parameters[row.id] }
              if (val.floatValue !== undefined) {
                p[key] = val.floatValue
              } else {
                delete p[key]
              }
              updateParameters({ ...parameters, [row.id]: p })
            }}
          />
        )

      case 'hurdleHeight':
        return (
          <SbDropdownButton
            selected={parameters[row.id] ? parameters[row.id][key]?.toString() || null : null}
            items={['76', '84', '91.4', '100', '106.9'].map(v => ({
              id: v,
              value: v,
              name: `${v} cm`,
            }))}
            onChange={chosen => {
              let p = { ...parameters[row.id] }
              p[key] = parseFloat(chosen)
              updateParameters({ ...parameters, [row.id]: p })
            }}
          />
        )

      default:
        return value
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
      sbLocale: { formatDate },
      athletes,
      requiredParameters,
    } = this.props

    return (
      <SbDataTable
        id="athletes-table"
        className="list-table"
        containerStyle={{ overflow: 'visible' }}
        sortable={false}
        cellFormatter={(value, key, row) => this.cellFormatter(value, key, row)}
        columns={[
          { name: 'name', label: _(t.name) },
          { name: 'birthdate', label: _(t.birthdate) },
          { name: 'sex', label: _(t.sex) },
          ...requiredParameters.map(p => ({
            name: p,
            label: t[p] ? _(t[p]) : p,
          })),
        ]}
        data={athletes.map(a => ({
          id: a.id,
          name: `${a.firstName} ${a.lastName}`,
          birthdate: formatDate(new Date(a.birthdate)),
          sex: a.female ? _(t.female) : _(t.male),
        }))}
      />
    )
  }
}

export const ParameterEditor = injectSbLocale(injectIntl(_ParameterEditor))
