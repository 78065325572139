import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import { Mutation } from '@apollo/react-components'

import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

import { messages as t } from './users-page-i18n'
import { deleteAthleteMutation, allAccountsQuery } from './users-page-queries'

class _DeleteConfirmationModal extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  }

  renderFooter = () => {
    const {
      id,
      intl: { formatMessage: _ },
      close,
      onSuccess,
      onError
    } = this.props

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">
          <SbButton onClick={close}>{_(t.cancel)}</SbButton>
        </div>
        <div className="sb-modal-center"></div>
        <div className="sb-modal-right">
          <Mutation
            mutation={deleteAthleteMutation}
            refetchQueries={[{ query: allAccountsQuery }]}
            onCompleted={() => {
              onSuccess()
              close()
            }}
            onError={() => {
              onError()
              close()
            }}
            variables={{ id }}
          >
            {(postMutation, { loading }) => (
              <SbButton className={'destructive'} onClick={postMutation} loading={loading} disabled={loading}>
                {_(t.delete)}
              </SbButton>
            )}
          </Mutation>
        </div>
      </div>
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      name,
    } = this.props

    return (
      <SbModal Header={<div />} Footer={this.renderFooter()}>
        <p>
          <FormattedMessage {...t.sureToDelete} values={{ name: <b>{name}</b> }} /> ?
        </p>
        <p>{_(t.cannotBeUndone)}.</p>
      </SbModal>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
})

export const DeleteConfirmationModal = injectIntl(connect(null, mapDispatchToProps)(_DeleteConfirmationModal))
