import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { StatusBar } from 'skybase-ui/skybase-components/status-bar'
import { toggleLeftColumn as toggleLeftColumnAction } from 'skybase-ui/skybase-components/sb-left-column/actions'
import { SbLanguageSwitcher } from 'skybase-ui/skybase-components/sb-language-switcher/sb-language-switcher'

import { UserWidget } from 'skybase-oauth/oauth/components'

import { messages as t } from '../about-page/about-page-i18n'

class Header extends Component {
  renderIdentifierZone = _ => <span className="fl-container fl-align-items-center">{_(t.appName)}</span>

  renderInfoZone = () => <SbLanguageSwitcher className="status-bar-right fl-row" />

  renderSpecificZone = () => {
    return (
      <div className="fl-container fl-row fl-justify-end fl-align-items-center fl-grow-1">
        <span className="device-name" />
        <div className="item fl-row fl-justify-sb fl-align-items-center">
          <UserWidget
            hideSwitchTenant
            hideViewProfile
          />
          <span className="separator" />
        </div>
      </div>
    )
  }

  render() {
    const { handleOnToggleLeftColumn } = this.props
    const {
      intl: { formatMessage },
    } = this.props

    return (
      <StatusBar
        onMenuItemClick={() => handleOnToggleLeftColumn()}
        identifierZone={this.renderIdentifierZone(formatMessage)}
        specificZone={this.renderSpecificZone()}
        infoZone={this.renderInfoZone()}
        helpProps={{ href: '/about', target: '_blank' }}
      />
    )
  }
}

const mapDispatchToProps = dispatch => ({
  handleOnToggleLeftColumn() {
    dispatch(toggleLeftColumnAction())
  },
  dispatch,
})

export default injectIntl(connect(null, mapDispatchToProps)(Header))
