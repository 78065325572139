import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import { AclControl } from 'skybase-oauth/auth/acl-control'
import { SbAccessDeniedPage } from 'skybase-ui/skybase-pages/sb-access-denied-page'

export const AclRoute = props => {
  const { children, component, overrideComponent, readPermissions, writePermissions, ...rest } = props
  return (
    <Route
      {...rest}
      render={routerProps => {
        const override = overrideComponent ? (
          React.createElement(overrideComponent, routerProps)
        ) : (
          <SbAccessDeniedPage {...routerProps} />
        )

        return (
          <AclControl
            readPermissions={readPermissions}
            writePermissions={writePermissions}
            overrideComponent={override}
          >
            {React.createElement(component, routerProps)}
          </AclControl>
        )
      }}
    >
      {children}
    </Route>
  )
}

AclRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  overrideComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  readPermissions: PropTypes.arrayOf(PropTypes.string),
  writePermissions: PropTypes.arrayOf(PropTypes.string),
}

AclRoute.defaultProps = {
  children: null,
  component: null,
  overrideComponent: null,
  readPermissions: null,
  writePermissions: null,
}
