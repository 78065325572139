import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { injectIntl, FormattedMessage } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'
import classNames from 'classnames'

import config from '@/config'

import {
  Sb21Layout,
  SbDynamicTabs,
  SbTab,
  SbDataTable,
  SbLabel,
  SbTextbox,
  SbButtonBar,
  SbRadioButton,
  SbButton,
  SbInlineMessage,
  SbMultiselect,
  SbDateTime,
} from 'skybase-ui/skybase-components'
import { INLINE_MESSAGE_TYPE_ERROR } from 'skybase-ui/skybase-components/sb-inline-message/constants'
import { showSuccessToast, showErrorToast } from 'skybase-ui/skybase-components/sb-toastr'
import { StatusBullet } from '@/components/status-bullet'

import { withAclList } from 'skybase-oauth/auth/with-acl-list'

import { openModal } from 'skybase-ui/skybase-core/base/actions'
import { CREATE_USER_MODAL_ID } from '@/components/create-user'
import { DELETE_USER_CONFIRMATION_MODAL_ID } from './constants'

import { Query, Mutation } from '@apollo/react-components'

import { currentUserId } from '@/common/oauth'

import { isEmail } from '@/utils'

import { messages as t } from './users-page-i18n'
import { allAccountsQuery, updateTutorMutation, updateAthleteMutation, athleteDetailsQuery } from './users-page-queries'
import {
  deletePermission,
  athletesCreatePermission,
  tutorsCreatePermission,
  athletesUpdatePermission,
} from './users-page-permissions'

import './users-page.scss'

class _UsersPage extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    showErrorToast: PropTypes.func.isRequired,
    showSuccessToast: PropTypes.func.isRequired,
    openDeleteModal: PropTypes.func.isRequired,
    openCreateUserModal: PropTypes.func.isRequired,
    aclList: PropTypes.shape({
      [deletePermission]: PropTypes.bool,
      [athletesCreatePermission]: PropTypes.bool,
      [athletesUpdatePermission]: PropTypes.bool,
      [tutorsCreatePermission]: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {}

  constructor(props) {
    super(props)
    this.state = {
      selectedAccount: null,
      athleteDetails: null,
      validated: false,
      nameFilter: null,
      typeFilter: null,
    }
  }

  cellFormatter = (value, key, row) => {
    const {
      intl: { formatMessage: _ },
    } = this.props

    const { nameFilter } = this.state

    switch (key) {
      case 'firstName':
      case 'lastName': {
        if (nameFilter) {
          const pos = value.toLowerCase().indexOf(nameFilter.toLowerCase())
          if (pos !== -1) {
            return (
              <div>
                {value.substr(0, pos)}
                <span style={{ backgroundColor: '#afa' }}>{value.substr(pos, nameFilter.length)}</span>
                {value.substr(pos + nameFilter.length)}
              </div>
            )
          }
        }
        return value
      }
      case 'disabled': {
        return (
          <StatusBullet
            unknown={Boolean(row.accountId && value !== true && value !== false)}
            enabled={Boolean(row.accountId && value === false)}
          />
        )
      }
      case 'type': {
        return _(t[value.toLowerCase()])
      }
      default:
        return value
    }
  }

  rowFormatter = row => {
    const { selectedAccount } = this.state
    const selected = row.id === selectedAccount?.id ? 'selected' : ''

    return {
      onClick: () => {
        this.setState({
          selectedAccount: row.id === selectedAccount?.id ? null : row,
          validated: false,
          athleteDetails: null,
        })
      },
      className: classNames({ selected }),
    }
  }

  validate() {
    const { selectedAccount } = this.state
    this.setState({ validated: true })

    return (
      selectedAccount.firstName?.length &&
      selectedAccount.lastName?.length &&
      (selectedAccount.disabled !== false || (selectedAccount.email?.length && isEmail(selectedAccount.email)))
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      showSuccessToast,
      showErrorToast,
      openDeleteModal,
      openCreateUserModal,
      aclList,
    } = this.props

    const { selectedAccount, athleteDetails, validated, nameFilter, typeFilter } = this.state
    return (
      <Query query={allAccountsQuery}>
        {({ loading, data }) => {
          let filteredData = data?.allAccounts || []
          if (typeFilter) {
            filteredData = filteredData.filter(row => row.type === typeFilter)
          }
          if (nameFilter) {
            filteredData = filteredData.filter(
              row =>
                row.firstName.toLowerCase().includes(nameFilter.toLowerCase()) ||
                row.lastName.toLowerCase().includes(nameFilter.toLowerCase()),
            )
          }

          return (
            <Sb21Layout title={_(t.usermanagement)}>
              <div>
                <div className="sb-heading fl-row fl-align-items-center">
                  <h1>{_(t.usermanagement)}</h1>
                  {(aclList[tutorsCreatePermission] || aclList[athletesCreatePermission]) && (
                    <SbButton
                      className={'sb-btn-with-icon-text primary'}
                      style={{ top: '2px' }}
                      onClick={() =>
                        openCreateUserModal({
                          refetchQueries: [{ query: allAccountsQuery }],
                          onSuccess: msg => showSuccessToast(msg),
                          onError: msg => showErrorToast(msg),
                        })
                      }
                    >
                      <i className="sbi-plus" />
                      {_(t.addNewUser)}
                    </SbButton>
                  )}
                </div>
                <div className="fl-container" style={{ justifyContent: 'left', lineHeight: '1em', margin: '1.25em 0' }}>
                  <SbTextbox
                    value={nameFilter || ''}
                    style={{ marginRight: '3em' }}
                    placeholder={`${_(t.search)}...`}
                    onChange={e => {
                      this.setState({
                        nameFilter: e.target.value.length ? e.target.value : null,
                      })
                    }}
                  />
                  <SbButtonBar style={{ marginRight: '3em' }}>
                    <SbRadioButton checked={!typeFilter} onChange={() => this.setState({ typeFilter: null })}>
                      {_(t.allAccounts)}
                    </SbRadioButton>
                    <SbRadioButton
                      checked={typeFilter === 'Tutor'}
                      onChange={() => this.setState({ typeFilter: 'Tutor' })}
                    >
                      {_(t.tutorsOnly)}
                    </SbRadioButton>
                    <SbRadioButton
                      checked={typeFilter === 'Athlete'}
                      onChange={() => this.setState({ typeFilter: 'Athlete' })}
                    >
                      {_(t.athletesOnly)}
                    </SbRadioButton>
                  </SbButtonBar>
                  <SbButton
                    icon="sbi-close"
                    disabled={!(nameFilter || typeFilter)}
                    onClick={() => this.setState({ nameFilter: null, typeFilter: null })}
                  >
                    {_(t.clearFilters)}
                  </SbButton>
                </div>

                <SbDataTable
                  id="users-table"
                  className="list-table"
                  cellFormatter={this.cellFormatter}
                  rowFormatter={row => this.rowFormatter(row)}
                  columns={[
                    { name: 'firstName', label: _(t.firstName) },
                    { name: 'lastName', label: _(t.lastName) },
                    { name: 'type', label: _(t.type) },
                    { name: 'disabled', label: _(t.canLogin) },
                  ]}
                  defaultSortBy={{ sortCol: 'lastName', sortOrder: 'asc' }}
                  loading={loading}
                  data={filteredData}
                  enablePagination
                  asyncData={false}
                  paginationProps={{
                    goToPage: false,
                    pageSizeLabel: _(t.showRecords),
                    previousLabel: '< ' + _(t.previous),
                    nextLabel: _(t.next) + ' >',
                  }}
                />
              </div>
              <SbDynamicTabs>
                <SbTab>
                  <SbTab.TabTitle>{_(t.details)}</SbTab.TabTitle>
                  <SbTab.Content>
                    {selectedAccount && (
                      <React.Fragment>
                        <div>
                          <SbLabel
                            title={_(t.firstName)}
                            hasError={!Boolean(selectedAccount.firstName)}
                            errorMessage={`${_(t.firstName)} ${_(t.isRequired)}`}
                            required={true}
                          >
                            <SbTextbox
                              value={selectedAccount.firstName}
                              placeholder={_(t.firstName)}
                              hasError={!Boolean(selectedAccount.firstName)}
                              onChange={e => {
                                this.setState({
                                  selectedAccount: { ...selectedAccount, firstName: e.target.value },
                                })
                              }}
                            />
                          </SbLabel>
                          <SbLabel
                            title={_(t.lastName)}
                            hasError={!Boolean(selectedAccount.lastName)}
                            errorMessage={`${_(t.lastName)} ${_(t.isRequired)}`}
                            required={true}
                          >
                            <SbTextbox
                              value={selectedAccount.lastName}
                              placeholder={_(t.lastName)}
                              hasError={!Boolean(selectedAccount.lastName)}
                              onChange={e => {
                                this.setState({ selectedAccount: { ...selectedAccount, lastName: e.target.value } })
                              }}
                            />
                          </SbLabel>

                          <SbLabel title={_(t.canLogin)}>
                            <SbButtonBar
                              disabled={
                                (selectedAccount.type === 'Athlete' && config.athleteLoginDisabled) ||
                                selectedAccount.accountId === currentUserId() ||
                                (selectedAccount.accountId &&
                                  selectedAccount.disabled !== true &&
                                  selectedAccount.disabled !== false)
                              }
                            >
                              <SbRadioButton
                                checked={selectedAccount.disabled === false}
                                onChange={() => {
                                  this.setState({ selectedAccount: { ...selectedAccount, disabled: false } })
                                }}
                              >
                                {_(t.enabled)}
                              </SbRadioButton>
                              <SbRadioButton
                                checked={!(selectedAccount.disabled === false)} // could also be undefined, that's why we can't just check for 'true'
                                onChange={() => {
                                  this.setState({ selectedAccount: { ...selectedAccount, disabled: true } })
                                }}
                              >
                                {_(t.disabled)}
                              </SbRadioButton>
                            </SbButtonBar>
                          </SbLabel>
                          {(selectedAccount.accountId || selectedAccount.disabled === false) && (
                            <SbLabel
                              title={_(t.email)}
                              required={true}
                              errorMessage={
                                !Boolean(selectedAccount.email) ? `Email ${_(t.isRequired)}` : _(t.invalidEmail)
                              }
                              hasError={
                                validated && (!Boolean(selectedAccount.email) || !isEmail(selectedAccount.email))
                              }
                            >
                              <SbTextbox
                                hasError={
                                  validated && (!Boolean(selectedAccount.email) || !isEmail(selectedAccount.email))
                                }
                                value={selectedAccount.email || ''}
                                placeholder={'Email'}
                                disabled={Boolean(selectedAccount.accountId)}
                                onChange={e => {
                                  this.setState({ selectedAccount: { ...selectedAccount, email: e.target.value } })
                                }}
                              />
                            </SbLabel>
                          )}
                          {selectedAccount.accountId &&
                            selectedAccount.disabled !== true &&
                            selectedAccount.disabled !== false && (
                              <SbInlineMessage type={INLINE_MESSAGE_TYPE_ERROR} message={_(t.loginStatusUnknown)} />
                            )}
                        </div>
                        <hr />
                        <div>
                          {selectedAccount.type === 'Athlete' && aclList[athletesUpdatePermission] && (
                            <Query query={athleteDetailsQuery} variables={{ id: selectedAccount.id }}>
                              {({ data, loading }) => {
                                if (!athleteDetails && data) {
                                  this.setState({
                                    athleteDetails: {
                                      female: data.athleteDetails.female,
                                      birthdate: new Date(data.athleteDetails.birthdate),
                                    },
                                  })
                                }
                                return (
                                  <React.Fragment>
                                    <SbLabel title={_(t.sex)}>
                                      <SbButtonBar disabled={loading} editable={false}>
                                        <SbRadioButton
                                          checked={athleteDetails?.female}
                                          onChange={() => {
                                            this.setState({ athleteDetails: { ...athleteDetails, female: true } })
                                          }}
                                        >
                                          {_(t.female)}
                                        </SbRadioButton>
                                        <SbRadioButton
                                          checked={athleteDetails?.female === false}
                                          onChange={() => {
                                            this.setState({ athleteDetails: { ...athleteDetails, female: false } })
                                          }}
                                        >
                                          {_(t.male)}
                                        </SbRadioButton>
                                      </SbButtonBar>
                                    </SbLabel>
                                    <SbLabel title={_(t.birthdate)}>
                                      <SbDateTime
                                        type={'date'}
                                        disabled
                                        value={athleteDetails?.birthdate}
                                        onChange={chosen => {
                                          this.setState({
                                            athleteDetails: { ...athleteDetails, birthdate: chosen.value },
                                          })
                                        }}
                                        position={'top-left'}
                                      />
                                    </SbLabel>
                                  </React.Fragment>
                                )
                              }}
                            </Query>
                          )}
                          <SbLabel
                            title={selectedAccount.type === 'Athlete' ? _(t.tutoringTrainers) : _(t.tutoredAthletes)}
                          >
                            <SbMultiselect
                              items={data.allAccounts
                                .filter(a => Boolean(selectedAccount.type !== a.type))
                                .map(a => ({ value: a.id, label: `${a.firstName} ${a.lastName}` }))}
                              selected={selectedAccount.connections.map(c => c.id)}
                              onChange={selected => {
                                this.setState({
                                  selectedAccount: {
                                    ...selectedAccount,
                                    connections: data.allAccounts.filter(a => selected.includes(a.id)),
                                  },
                                })
                              }}
                            />
                          </SbLabel>
                        </div>
                      </React.Fragment>
                    )}
                    {!selectedAccount && (
                      <div className="info-message">
                        <span className="sbi-big-info" />
                        <div>{_(t.pleaseselectuser)}</div>
                      </div>
                    )}
                  </SbTab.Content>

                  {selectedAccount && (
                    <SbTab.Footer>
                      <Mutation
                        mutation={selectedAccount.type === 'Athlete' ? updateAthleteMutation : updateTutorMutation}
                        refetchQueries={[{ query: allAccountsQuery }]}
                        onCompleted={() => {
                          showSuccessToast(_(t.updateSuccessful))
                          this.setState({
                            validated: false,
                          })
                        }}
                        onError={() => {
                          showErrorToast(_(t.errorOnUpdate))
                          this.setState({ validated: false, selectedAccount: null, athleteDetails: null })
                        }}
                      >
                        {(postMutation, { loading }) => (
                          <SbLabel>
                            <SbButton
                              className={'primary sb-width-100pct'}
                              onClick={() => {
                                if (this.validate()) {
                                  const { id, firstName, lastName, type, disabled, email } = selectedAccount
                                  const connectionIds = selectedAccount.connections.map(t => t.id)
                                  let variables = { id, firstName, lastName, type, disabled, email, connectionIds }

                                  if (selectedAccount.type === 'Athlete' && athleteDetails) {
                                    variables = { ...variables, ...athleteDetails }
                                  }

                                  postMutation({
                                    variables,
                                  })
                                }
                              }}
                              loading={loading}
                              disabled={loading}
                            >
                              {_(t.updateUser)}
                            </SbButton>
                          </SbLabel>
                        )}
                      </Mutation>
                      {selectedAccount?.type === 'Athlete' && aclList[deletePermission] && (
                        <SbButton
                          className="sb-width-100pct destructive"
                          onClick={() => {
                            const name = `${selectedAccount.firstName} ${selectedAccount.lastName}`
                            openDeleteModal({
                              id: selectedAccount.id,
                              name,
                              onSuccess: () => {
                                showSuccessToast(<FormattedMessage {...t.deletionSuccessful} values={{ name }} />)
                                this.setState({ selectedAccount: null })
                              },
                              onError: () =>
                                showErrorToast(<FormattedMessage {...t.errorOnDelete} values={{ name }} />),
                            })
                          }}
                        >
                          {_(t.deleteUser)}
                        </SbButton>
                      )}
                    </SbTab.Footer>
                  )}
                </SbTab>
              </SbDynamicTabs>
            </Sb21Layout>
          )
        }}
      </Query>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    showSuccessToast: (params, config = {}) => showSuccessToast(params, config)(dispatch),
    showErrorToast: (params, config = {}) => showErrorToast(params, config)(dispatch),
    openDeleteModal: config => dispatch(openModal(DELETE_USER_CONFIRMATION_MODAL_ID, config)),
    openCreateUserModal: config => dispatch(openModal(CREATE_USER_MODAL_ID, config)),
  }
}

export const UsersPage = injectIntl(
  connect(
    null,
    mapDispatchToProps,
  )(
    withAclList(_UsersPage, [
      deletePermission,
      tutorsCreatePermission,
      athletesCreatePermission,
      athletesUpdatePermission,
    ]),
  ),
)
