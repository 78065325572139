// Hardcoded sprint parameters
export const parameterUnits = {
    weight: 'kg',
    height: 'cm',
    frontAngle: '°',
    rearAngle: '°',
    frontDistance: 'cm',
    rearDistance: 'cm',
    hurdleHeight: 'cm',
    hurdleDistance: 'm',
  }