import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// SkyBase reducers.
import { appRootReducer } from 'skybase-ui/skybase-core/base/reducers'
import { localeReducer, STATE_KEY_NAME as localeStateKeyName } from 'skybase-ui/skybase-core/locales'
import { toastrReducer } from 'skybase-ui/skybase-components/sb-toastr'

import { sbNotificationsDropdownReducer } from 'skybase-ui/skybase-components/sb-notifications/sb-notifications-dropdown-reducer'
import { notificationsReducer } from 'skybase-ui/skybase-core/notifications/notifications-reducer'
import { licensesReducer, LICENSES_STATE_KEY_NAME } from 'skybase-ui/widgets/licenses'
import { oAuthFactoryReducer, O_AUTH_STATE_KEY_NAME } from 'skybase-oauth'

import { leftColumnReducer, leftColumnStateKeyName } from '@/containers/left-column'

import { modalReducer, modalsStateKeyName } from '@/common/modals'

/**
 * @description
 * Combines all reducers required by our app.
 */
export const createAppReducer = history =>
  combineReducers({
    // SkyBase reducers:
    appRoot: appRootReducer,
    notifications: notificationsReducer,
    notificationsDropdown: sbNotificationsDropdownReducer,
    toasts: toastrReducer,
    router: connectRouter(history),
    [O_AUTH_STATE_KEY_NAME]: oAuthFactoryReducer,
    // pages:
    [LICENSES_STATE_KEY_NAME]: licensesReducer,
    //other
    modal: modalReducer,

    // custom:
    [leftColumnStateKeyName]: leftColumnReducer,
    [localeStateKeyName]: localeReducer,
    [modalsStateKeyName]: modalReducer,
  })
