import React, { PureComponent } from 'react'

import { SbButton, SbDropdown, SbDropdownOption } from 'skybase-ui/skybase-components'
import { Mutation } from '@apollo/react-components'

import { saveAs } from 'file-saver'

import { gql } from '@apollo/client'

const mutation = gql`
  mutation assignedFileDownloadUrl($id: ID!) {
    assignedFileDownloadUrl(id: $id)
  }
`

export class FileDownloadButton extends PureComponent {
  constructor(props) {
    super(props)
    const { fileNames } = this.props

    if (fileNames === undefined) {
      this.state = { fileLoading: false, fId: '', fName: '', fileType: null, currentFileNames: undefined }
    }
    else {
      const [first] = Object.keys(fileNames).filter(k => fileNames[k].fileId !== undefined)
      this.state = {
        fileLoading: false,
        fId: fileNames[first].fileId,
        fName: fileNames[first].fileName,
        fileType: first,
        currentFileNames: fileNames,
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { fileNames } = this.props
    const { currentFileNames} = this.state

    if (fileNames !== currentFileNames) {
      const [first] = Object.keys(fileNames).filter(k => fileNames[k].fileId !== undefined)
      this.setState({ fId: fileNames[first].fileId, fName: fileNames[first].fileName, fileType: first, currentFileNames: fileNames })
    }
  }

  fileTypeSelect() {
    const { fileNames } = this.props
    const { fileType } = this.state
    if (fileType === null) {
      return (<></>)
    }
    return (
      <SbDropdown
        selected={fileType}
        style={{width: '60px', minWidth: '50px', paddingRight: '5px'}}
        onChange={type => {this.setState({fileType: type})}}
      >
        {Object.keys(fileNames).map(type => {
          return (<SbDropdownOption key={type} disabled={fileNames[type].fileId === undefined} value={type}>{type}</SbDropdownOption>)
        })}
      </SbDropdown>
    )
  }

  render() {
    const { fileLoading, fileType } = this.state
    const { fileId, fileName, fileNames, children, ...other } = this.props

    const id = fileType === null ? fileId : fileNames[fileType].fileId
    const name = fileType === null ? fileName : fileNames[fileType].fileName

    return (
      <Mutation
        mutation={mutation}
        variables={{ id: id }}
        onCompleted={result => {
          fetch(result.assignedFileDownloadUrl)
            .then(res => res.blob())
            .then(blob => {
              saveAs(blob, name)
            })
            .finally(() => {
              this.setState({ fileLoading: false })
            })
        }}
      >
        {(fetchUrl, { loading }) => (
          <div>
            {this.fileTypeSelect()}
            <SbButton
              {...other}
              className="primary"
              loading={this.props.loading || loading || fileLoading}
              disabled={this.props.disabled || loading || fileLoading}
              onClick={() => {
                fetchUrl()
                this.setState({ fileLoading: true })
              }}
            >
              {children}
            </SbButton>
          </div>
        )}
      </Mutation>
    )
  }
}
