import React from 'react'
import Chart from 'react-apexcharts'
import { injectIntl, FormattedNumber } from 'react-intl'

import { SbDataTable } from 'skybase-ui/skybase-components'

import { messages as t } from '../measurements-page-i18n'

import { colors, annotationColors } from '../constants'

const numberOfSteps = c => {
  try {
    const stepsPerChar = Object.keys(c).map(k => c[k].values.filter(v => v !== 'NaN').length)
    return Math.max(0, ...stepsPerChar)
  } catch (TypeError) {
    return 0
  }
}

const valueTable = (mId, characteristics, _) => (
  <SbDataTable
    id={`characteristicsTable-${mId}`}
    cellFormatter={(value, key) => {
      if (key !== 'title') {
        return { value, style: { textAlign: 'center' } }
      }
      return value
    }}
    columns={[
      { name: 'title', label: _(t.step), headerStyle: { width: '20%' } },
      ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(i => ({
        name: `${i}`,
        label: `${i}`,
        headerStyle: { textAlign: 'center' },
      })),
    ]}
    data={[
      'Velocity',
      'VelocityChange',
      'StepFrequency',
      'StepLength',
      'GroundContactTime',
      'GroupAirTimeSteps',
      'RelativeStepLength',
      'WeightedAccelerationImpulse',
      'WeightedBrakingImpulse',
      'WeightedAccelerationToBrakingImpulse',
      'ActivityIndex',
      'DutyFactor',
    ]
      .map(k => {
        const m = characteristics[k]
        if (!m || !m.xValues) return null
        if (m) {
          const row = { title: `${t[k] ? _(t[k]) : k} ${m.unit !== '' ? `[${m.unit}]` : ``}` }
          m.xValues.forEach((_, idx) => {
            row[`${idx + 1}`] =
              m.values[idx] === undefined || m.values[idx] === 'NaN' ? (
                '-'
              ) : (
                <FormattedNumber
                  value={m.values[idx]}
                  maximumFractionDigits={
                    [
                      'GroundContactTime',
                      'GroupAirTimeSteps',
                      'WeightedAccelerationImpulse',
                      'WeightedBrakingImpulse',
                      'WeightedAccelerationToBrakingImpulse',
                    ].includes(k)
                      ? 0
                      : 2
                  }
                />
              )
          })
          return row
        } else {
          return null
        }
      })
      .filter(Boolean)}
    sortable={false}
    style={{ marginBottom: '2em' }}
  />
)

const characteristicsToTable = (id, keys, mId, characteristics, _) => (
  <SbDataTable
    id={`${id}-Table-${mId}`}
    columns={[{ name: 'key', sortable: false, label: ' ' }, { name: 'value', sortable: false, label: ' ' }]}
    data={
      keys.map(k => {
        const key = `${t[k] ? _(t[k]) : k}`
        const m = characteristics[k]
        if (m) {
          const value = m.value === undefined || m.value === 'NaN' ? (
            '-'
          ) : (
            <React.Fragment><FormattedNumber
              value={m.value}
              maximumFractionDigits={1}
            /> {m.unit}
            </React.Fragment>
          )
          return { key, value }
        } else {
          return { key, value: '-' }
        }
      })
    }
  />
)

const sprintStartTables = (mId, characteristics, _) => (
  <React.Fragment>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '45%' }}>
        {characteristicsToTable('Force', ['MaxForceRearTotal',
          'MaxForceFrontTotal',
          'MaxForceHandsTotal',
          'MaxForceCOMTotal'
        ], mId, characteristics, _)}
      </div>
      <div style={{ width: '45%' }}>
        {characteristicsToTable('RFD', ['MaxRFDRearTotal',
          'MaxRFDFrontTotal',
          'MaxRFDHandsTotal',
          'MaxRFDCOMTotal'
        ], mId, characteristics, _)}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '45%' }}>
        {characteristicsToTable('RFD', ['AngleOfForceVectorAtToRearBlockFront',
          'AngleOfForceVectorAtToRearBlockCOM',
          'AngleOfForceVectorAtToHandsRear',
          'AngleOfForceVectorAtToHandsFront',
          'AngleOfForceVectorAtToHandsCOM'
        ], mId, characteristics, _)}
      </div>
      <div style={{ width: '45%' }}>
        {characteristicsToTable('RFD', ['ReactionTime',
          'BlockTimeCOM',
          'PushTimeRear',
          'PushTimeFront',
          'PushTimeHands',
          'PushTimeCOM'
        ], mId, characteristics, _)}
      </div>
    </div>
  </React.Fragment>

)

const velocityFrequencyChart = (mId, characteristics, _) => {
  let options, series

  const variableCharIdsToDisplay = ['Velocity', 'StepFrequency']
  const constantCharIdsToDisplay = ['MeanVelocityOverSteps', 'MeanVelocityOverWayTime', 'MeanStepFrequency']

  let chartChars = {}
  Object.keys(characteristics)
    .filter(k => variableCharIdsToDisplay.includes(k))
    .forEach(k => (chartChars[k] = characteristics[k]))
  const steps = numberOfSteps(chartChars)

  try {
    series = variableCharIdsToDisplay
      .map(c => {
        return chartChars[c]
          ? {
            name: t[c] ? _(t[c]) : c,
            data: chartChars[c].values.slice(0, steps).map(v => (v === 'NaN' ? null : v.toFixed(2))),
          }
          : null
      })
      .filter(Boolean)
  } catch (TypeError) {
    return null
  }

  if (series.length) {
    options = {
      chart: {
        id: `${mId}-vel-freq-chart`,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      colors,
      xaxis: {
        categories: Array.from(Array(steps).keys()).map(i => i + 1),
      },
      yaxis: [
        { title: { text: `${_(t['Velocity'])} [m/s]`, style: { fontSize: '10px' } }, seriesName: 'Velocity' },
        {
          title: { text: `${_(t['StepFrequency'])} [Hz]`, rotate: 90, style: { fontSize: '10px' } },
          opposite: true,
          seriesName: 'StepFrequency',
        },
      ],
      annotations: {
        yaxis: constantCharIdsToDisplay
          .map((k, i) => {
            const v = characteristics[k]
            if (v) {
              return {
                strokeDashArray: 0,
                opacity: 1,
                y: v.value === 'NaN' || !('value' in v) ? null : v.value,
                yAxisIndex: k === 'MeanStepFrequency' ? 1 : 0,
                borderColor: annotationColors[i % annotationColors.length],
                label: {
                  textAnchor: k === 'MeanStepFrequency' ? 'right' : 'left',
                  position: k === 'MeanStepFrequency' ? 'right' : 'left',
                  offsetX: k === 'MeanStepFrequency' ? -135 : 0,
                  offsetY: 16,
                  style: {
                    color: '#fff',
                    background: annotationColors[i % annotationColors.length],
                  },
                  text: `${t[k] ? _(t[k]) : k}: ${v.value === 'NaN' || !('value' in v) ? null : v.value.toFixed(2)}`,
                },
              }
            }
            return null
          })
          .filter(Boolean),
      },
    }

    return <Chart options={options} series={series} height={350} />
  }

  return null
}

const impulseChart = (mId, characteristics, _) => {
  const charIdsToDisplay = [
    'WeightedAccelerationImpulse',
    'WeightedBrakingImpulse',
    'WeightedAccelerationToBrakingImpulse',
  ]

  let chartChars = {}
  Object.keys(characteristics)
    .filter(k => charIdsToDisplay.includes(k))
    .forEach(k => (chartChars[k] = characteristics[k]))

  if (chartChars) {
    const steps = numberOfSteps(chartChars)
    let series
    try {
      series = charIdsToDisplay
        .map(c => {
          return chartChars[c]
            ? {
              name: t[c] ? _(t[c]) : c,
              data: chartChars[c].values.slice(0, steps).map(v => (v === 'NaN' ? null : Math.round(v))),
            }
            : null
        })
        .filter(Boolean)
    } catch (TypeError) {
      return null
    }


    const options = chartChars[charIdsToDisplay[0]] ? {
      chart: {
        id: `${mId}-impulse-chart`,
        type: 'bar',
        zoom: {
          enabled: false,
        },
      },
      colors,
      xaxis: {
        categories: Array.from(Array(steps).keys()).map(i => i + 1),
      },
      yaxis: [{ title: { text: `[${chartChars[charIdsToDisplay[0]].unit}]`, style: { fontSize: '10px' } } }],
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
      },
    } : {}

    return <Chart options={options} type={'bar'} series={series} height={250} />
  }

  return null
}

const barChart = (title, ids, mId, characteristics, _) => {

  const series = ids
    .map(c => {
      try {
        return characteristics[c]
          ? {
            name: t[c] ? _(t[c]) : c,
            data: [characteristics[c].value && characteristics[c].value !== 'NaN' ? characteristics[c].value.toFixed(1) : null],
          }
          : null
      } catch (TypeError) {
        return null
      }
    })
    .filter(Boolean)

  if (series.length) {
    const options = {
      chart: {
        id: `${mId}-${ids.join('-')}-chart`,
        type: 'bar',
        zoom: {
          enabled: false,
        },
      },
      colors,
      xaxis: {
        categories: [title],
      },
      yaxis: [{ title: { text: `[${characteristics[ids[0]].unit}]`, style: { fontSize: '10px' } } }],
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
      },
    }

    return <Chart options={options} type={'bar'} series={series} height={250} />

  }

  return <React.Fragment/>

}

const barCharts = (mId, characteristics, _) => (
  <React.Fragment>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '45%' }}>
        {barChart(_(t.averagePowerHorizontal), ['ChartAveragePowerHorizontal1', 'ChartAveragePowerHorizontal2'], mId, characteristics, _)}
      </div>
      <div style={{ width: '45%' }}>
        {barChart(_(t.averageForceHorizontal), ['ChartAverageForceHorizontal1', 'ChartAverageForceHorizontal2'], mId, characteristics, _)}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '45%' }}>
        {barChart(_(t.velocityHorizontal), ['ChartVelocityHorizontal1', 'ChartVelocityHorizontal2'], mId, characteristics, _)}
      </div>
      <div style={{ width: '45%' }}>
        {barChart(_(t.maximalRfdHorizontal), ['ChartMaximalRFDHorizontal1', 'ChartMaximalRFDHorizontal2'], mId, characteristics, _)}
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <div style={{ width: '45%' }}>
        {barChart(_(t.averageRfdHorizontal), ['ChartAverageRFDHorizontal1', 'ChartAverageRFDHorizontal2'], mId, characteristics, _)}
      </div>
      <div style={{ width: '45%' }}>
        {barChart(_(t.times), ['ChartTime1', 'ChartTime2', 'ChartTime3'], mId, characteristics, _)}
      </div>
    </div>
  </React.Fragment>
)

export const SprintStartCharacteristics = injectIntl(({ measurementId, characteristics, intl: { formatMessage: _ } }) => {
  return (
    <React.Fragment>
      <div style={{ height: '30px' }} />
      <h3>{_(t.stepParameters)}</h3>
      {valueTable(measurementId, characteristics, _)}
      <div style={{ height: '30px' }} />
      <h3>{_(t.velocityAndStepFrequency)}</h3>
      {velocityFrequencyChart(measurementId, characteristics, _)}
      <div style={{ height: '30px' }} />
      <h3>{_(t.startBlockParameters)}</h3>
      {sprintStartTables(measurementId, characteristics, _)}
      <div style={{ height: '30px' }} />
      <h3>{_(t.accelerationAndBrakeImpulses)}</h3>
      {impulseChart(measurementId, characteristics, _)}
      <div style={{ height: '30px' }} />
      <h3>{_(t.selectedDiagrams)}</h3>
      {barCharts(measurementId, characteristics, _)}
    </React.Fragment>
  )
})
