import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  saveAndRecreateCSV: {
    id: 'comment-box.saveAndRecreateCSV',
    defaultMessage: 'Save and recreate the CSV files',
  },
  cancel: {
    id: 'comment-box.cancel',
    defaultMessage: 'Cancel',
  },
  csvIsBeingReCreaded: {
    id: 'comment-box.csvIsBeingReCreated',
    defaultMessage: 'The comment was added and the CSV files are being re-reated'
  },
  errorOnReCreation: {
    id: 'comment-box.errorOnRecreateCSV',
    defaultMessage: 'An error occurred when attempting to re-create the CSV files'
  },
})
