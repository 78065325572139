import { routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { enableBatching } from 'redux-batched-actions'
import * as _history from 'history'
// Middlewares required by SkyBase UI.
import { redirectMiddleware } from 'skybase-ui/skybase-core/middlewares/redirect-middleware'
import { localeMiddleware } from 'skybase-ui/skybase-core/locales/middlewares'
import { oauthMiddleware } from 'skybase-oauth'

import { createAppReducer } from '@/reducers'

import { loggerMiddleware, logoutMiddleware } from '@/middlewares'

export const history = _history.createBrowserHistory()

// Build the middleware for intercepting and dispatching navigation actions
const routerMiddlewareInstance = routerMiddleware(history)
const crashReporter = () => next => action => {
  try {
    return next(action)
  } catch (err) {
    console.error('crashReporter: Exception in store', err)
    throw err
  }
}

const middlewares = [
  redirectMiddleware,
  crashReporter,
  // App's middlewares:
  loggerMiddleware,
  logoutMiddleware,
  // Skybase-Middlewares
  localeMiddleware,
  oauthMiddleware,
  // 3d party:
  routerMiddlewareInstance,
  thunk,
]

export const createAppStore = (initialState = {}) =>
  createStore(
    enableBatching(createAppReducer(history)),
    initialState,
    compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
    ),
  )

const store = createAppStore()

window.store = store

export default store
