import { TOGGLE_LEFT_COLUMN } from 'skybase-ui/skybase-components/sb-left-column/actions'

import { DEFAULT_IS_COLLAPSED, STATE_KEY_NAME } from './constants'
import { getIsCollapsed } from './selectors'

export const leftColumnStateKeyName = STATE_KEY_NAME

/**
 * @description
 * Returns a default state for the reducer.
 */
const getDefaultState = () => {
  return {
    isCollapsed: DEFAULT_IS_COLLAPSED,
  }
}

/**
 * @description
 * Handles all actions related to LeftColumn.
 *
 * @param state
 * @param action
 */
export const leftColumnReducer = (state = getDefaultState(), action = {}) => {
  const { type } = action

  if (type === TOGGLE_LEFT_COLUMN) {
    const isCollapsed = getIsCollapsed(state)

    return {
      ...state,
      isCollapsed: !isCollapsed,
    }
  }

  return state
}
