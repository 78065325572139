import React from 'react'
import Chart from 'react-apexcharts'
import { injectIntl, FormattedNumber } from 'react-intl'

import { SbDataTable, SbLabel } from 'skybase-ui/skybase-components'

import { messages as t } from '../measurements-page-i18n'

import { colors } from '../constants'

const forceChartsLast3Plates = (mId, characteristics, _) => {
  const forceChartIds = ['FzLast3Plates', 'FyLast3Plates', 'FxLast3Plates']

  return (
    <React.Fragment>
      {characteristics &&
        forceChartIds.map((c, idx) => {
          if (!characteristics[c]) return null

          const channel = characteristics[c]
          const series = [
            {
              name: c,
              data: channel.values.map((v, i) => [channel.xValues[i], v === 'NaN' ? null : v.toFixed(0)]),
            },
          ]

          const options = {
            chart: {
              id: `${c}Chart`,
              type: 'line',
              group: `${mId}ForcesLast3Plates`,
              animations: {
                enabled: false,
              },
            },
            stroke: {
              width: 1,
            },
            colors: [colors[idx]],
            xaxis: {
              type: 'numeric',
              title: {
                text: `${_(t.time)} [s]`,
                style: {
                  fontSize: '10px',
                },
              },
            },
            yaxis: {
              title: { text: `${_(t[c])} [N]`, style: { fontSize: '10px' } },
              labels: {
                minWidth: 40,
              },
            },
            tooltip: {
              x: {
                show: false,
                formatter: v => `${v.toFixed(3)} s`,
              },
              y: {
                formatter: v => `${v} N`,
              },
            },
          }

          return <Chart key={`${mId}-${c}`} options={options} series={series} height={150} />
        })}
    </React.Fragment>
  )
}

const numberOfSteps = c => {
  const stepsPerChar = Object.keys(c).map(k =>
    Array.isArray(c[k].values) ? c[k].values.filter(v => v !== 'NaN').length : 1,
  )
  return Math.max(0, ...stepsPerChar)
}

const valueTable = (mId, characteristics, _) => {
  return (
    <SbDataTable
      id={`characteristicsTable-${mId}`}
      cellFormatter={(value, key) => {
        if (key !== 'title') {
          return { value, style: { textAlign: 'center' } }
        }
        return value
      }}
      columns={[
        { name: 'title', label: _(t.step), headerStyle: { width: '20%' } },
        ...[1, 2, 3, 4, 5, 6, 7].map(i => ({
          name: `${i}`,
          label: `${i}`,
          headerStyle: { textAlign: 'center' },
        })),
      ]}
      data={[
        'Velocity',
        'VelocityChange',
        'StepFrequency',
        'StepLength',
        'GroundContactTime',
        'GroupAirTimeSteps',
        'RelativeStepLength',
        'WeightedAccelerationImpulse',
        'WeightedBrakingImpulse',
        'WeightedAccelerationToBrakingImpulse',
        'ActivityIndex',
        'DutyFactor',
      ]
        .map(k => {
          const m = characteristics[k]
          if (m) {
            const row = { title: `${t[k] ? _(t[k]) : k} [${m.unit}]` }
            m.xValues.forEach((_, idx) => {
              row[`${idx + 1}`] =
                m.values[idx] === undefined || m.values[idx] === 'NaN' ? (
                  '-'
                ) : (
                  <FormattedNumber
                    value={m.values[idx]}
                    maximumFractionDigits={
                      [
                        'GroundContactTime',
                        'GroupAirTimeSteps',
                        'WeightedAccelerationImpulse',
                        'WeightedBrakingImpulse',
                        'WeightedAccelerationToBrakingImpulse',
                      ].includes(k)
                        ? 0
                        : 2
                    }
                  />
                )
            })
            return row
          } else {
            return null
          }
        })
        .filter(Boolean)}
      sortable={false}
      style={{ marginBottom: '2em' }}
    />
  )
}

const impulseChart = (mId, characteristics, _) => {
  const charIdsToDisplay = [
    'WeightedAccelerationImpulse',
    'WeightedBrakingImpulse',
    'WeightedAccelerationToBrakingImpulse',
  ]

  let chartChars = {}
  Object.keys(characteristics)
    .filter(k => charIdsToDisplay.includes(k))
    .forEach(k => (chartChars[k] = characteristics[k]))

  if (chartChars) {
    const steps = numberOfSteps(chartChars)
    const series = charIdsToDisplay
      .map(c => {
        return chartChars[c]
          ? {
              name: t[c] ? _(t[c]) : c,
              data: chartChars[c].values.slice(0, steps).map(v => (v === 'NaN' ? null : Math.round(v))),
            }
          : null
      })
      .filter(Boolean)

    const options = {
      chart: {
        id: `${mId}-impulse-chart`,
        type: 'bar',
        zoom: {
          enabled: false,
        },
      },
      colors,
      xaxis: {
        categories: Array.from(Array(steps).keys()).map(i => i + 1),
      },
      yaxis: [{ title: { text: `[${chartChars[charIdsToDisplay[0]].unit}]`, style: { fontSize: '10px' } } }],
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#333'],
        },
      },
    }

    return <Chart options={options} type={'bar'} series={series} height={250} />
  }

  return null
}

const accVelDiagram = (postfix, mId, characteristics, _) => {
  const charIdsToDisplay = [`Fy${postfix}`, `Fz${postfix}`]
  const unit = characteristics[charIdsToDisplay[1]]?.unit

  const series = charIdsToDisplay.map(c => ({
    name: t[c] ? _(t[c]) : c,
    data: characteristics[c].values.map((v, i) => [characteristics[c].xValues[i], v === 'NaN' ? null : v.toFixed(2)]),
  }))

  const options = {
    chart: {
      id: `${postfix}Chart`,
      type: 'line',
      animations: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
    },
    colors,
    xaxis: {
      type: 'numeric',
      labels: {
        formatter: (value) => value.toFixed(2)
      },
      title: {
        text: `${_(t.time)} [s]`,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      title: { text: `${_(t[`${postfix}OverTime`])} [${unit}]`, style: { fontSize: '10px' } },
      labels: {
        minWidth: 40,
      },
    },
    tooltip: {
      x: {
        show: false,
        formatter: v => `${v.toFixed(3)} s`,
      },
      y: {
        formatter: v => `${v} ${unit}`,
      },
    },
  }

  return <Chart key={`${mId}-${postfix}`} options={options} series={series} height={250} />
}

export const LongJumpCharacteristics = injectIntl(({ measurementId, characteristics, intl: { formatMessage: _ } }) => {
  return (
    <React.Fragment>
      <h3>{_(t.last3Plates)}</h3>
      {forceChartsLast3Plates(measurementId, characteristics, _)}
      <div style={{ width: '100%', textAlign: 'center', fontSize: '150%'}}>
        <SbLabel title={_(t.jumpAngle)} style={{ width: '150px', margin: 'auto'}} inline>
          <b>
            {characteristics.JumpAngle?.values?.toFixed ? characteristics.JumpAngle.values.toFixed(1) : characteristics.JumpAngle.values}
            {characteristics.JumpAngle.unit}
          </b>
        </SbLabel>
      </div>
      <div style={{ height: '30px' }} />
      <h3>{_(t.stepParameters)}</h3>
      {valueTable(measurementId, characteristics, _)}
      <div style={{ height: '30px' }} />
      <h3>{_(t.accelerationAndBrakeImpulses)}</h3>
      {impulseChart(measurementId, characteristics, _)}
      {accVelDiagram('Acceleration', measurementId, characteristics, _)}
      {accVelDiagram('Velocity', measurementId, characteristics, _)}
    </React.Fragment>
  )
})
