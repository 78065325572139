/*
  General app's configuration.

  Important note:
  This file must be readable even without the need for Babel transpilation.
*/

/**
 * @description
 * Contains all the important settings that should be configurable.
 *
 * @public
 */
const config = {
  // Application name that will be displayed.
  appName: 'Kienbaum Analytics',
  // name to be used in actions, etc..
  actionNamespace: 'kienbaum.analytics',
  // Relative path to the UI app. It can't contain a slash at the end, e.g. `/my-relative-path/blah`.
  publicUrl: '/',
  // This is the name of the application that will show in the logger
  moduleName: 'kistler.analytics',
  // All supported cultures ordered by priority. The most important is considered default.
  cultures: ['en', 'de' ],
  // All supported languages ordered by priority. The most important is considered default.
  languages: ['en', 'de' ],
  // Maps the language selection to the culture.
  languageCultureMap: { en: 'en-150', de: 'de' },
  // Default-Culture
  defaultCulture: 'de',
  // Copyright
  copyright: new Date().getFullYear(),
  // Can athletesLogin?
  athleteLoginDisabled: false,
}

module.exports = config
exports.default = config
