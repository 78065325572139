import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { SbNotFoundPage } from 'skybase-ui/skybase-pages/sb-not-found-page'


import { App } from '@/containers/app'
import { HomePage } from '@/containers/home-page'
import { MeasurementsPage, measurementsPagePermissions } from '@/containers/measurements-page'
import { UsersPage, usersPagePermissions } from '@/containers/users-page'
import { LicensesPage } from '@/containers/licenses-page'
import { AboutPage } from '@/containers/about-page'
import { history } from '@/stores'

import { AclRoute } from '@/common/acl-route'


const Routes = () => {
  return (
    <ConnectedRouter history={history}>
      <App>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <AclRoute path="/measurements" exact writePermissions={measurementsPagePermissions} component={MeasurementsPage} />
          <AclRoute path="/users" exact writePermissions={usersPagePermissions} component={UsersPage} />
          <Route path="/about" exact component={AboutPage} />
          <Route path="/about/licenses" exact component={LicensesPage} />
          <Route component={SbNotFoundPage} />
        </Switch>
      </App>
    </ConnectedRouter>
  )
}

export default Routes
