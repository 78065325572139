import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'licenses.pageName',
    defaultMessage: 'Licenses',
  },
  aboutPageName: {
    id: 'about.pageName',
    defaultMessage: 'About',
  },
  licenses: {
    id: 'licenses.licenses',
    defaultMessage: 'Software Licenses',
  },
  licensesCopyright: {
    id: 'licenses.copyright',
    defaultMessage:
      'The software included in this product contains copyrighted software that is licensed under GPL, LGPL or other open-source licenses. A copy of these licenses is included in this document. You may obtain the complete corresponding source code for a fee covering the cost of distribution. This fee may include the cost of media, shipping and handling, and is available upon written request to: Kistler Instrumente AG, CH-8408 Winterthur, Switzerland. This offer is valid for a period of three (3) years after the last shipment of this product by Kistler.',
  },
  licenseVersion: {
    id: 'licenses.licenseVersion',
    defaultMessage: 'Version',
  },
  licenseType: {
    id: 'licenses.licenseType',
    defaultMessage: 'Type',
  },
  unknownLicense: {
    id: 'licenses.unknownLicense',
    defaultMessage: 'Unknown',
  },
  maintenance: {
    id: 'licenses.maintenance',
    defaultMessage: 'Maintenance',
  },
})
