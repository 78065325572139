import { gql } from '@apollo/client'

export const allTutorsQuery = gql`
  query {
    allTutors {
      id, firstName, lastName
    }
  }
`

export const createTutorMutation = gql`
  mutation createTutor($firstName: String!, $lastName: String!, $email: EmailAddress!) {
    createTutor(firstName: $firstName, lastName: $lastName, email: $email) {
      id,
      firstName,
      lastName
    }
  }
`
export const createAthleteMutation = gql`
  mutation createAthlete($firstName: String!, $lastName: String!, $birthdate: DateTime!, $female: Boolean!, $tutorIds: [ID]!, $email: EmailAddress) {
    createAthlete(firstName: $firstName, lastName: $lastName, birthdate: $birthdate, female: $female, tutorIds: $tutorIds, email: $email) {
      id,
      firstName,
      lastName
    }
  }
`