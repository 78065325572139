import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'

import { Mutation } from '@apollo/react-components'

import { sbLocaleShape, injectSbLocale } from 'skybase-ui/skybase-components/sb-locale'
import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbModal } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'
import { showSuccessToast, showErrorToast } from 'skybase-ui/skybase-components/sb-toastr'

import { ParameterEditor } from '@/components/parameter-editor'

import { messages as t } from './measurements-page-i18n'
import { updateParametersMutation } from './measurements-page-queries'

class _EditSessionParametersModal extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
    showErrorToast: PropTypes.func.isRequired,
    showSuccessToast: PropTypes.func.isRequired,
    sbLocale: sbLocaleShape.isRequired,
    close: PropTypes.func.isRequired,
    athletes: PropTypes.array.isRequired,
    movement: PropTypes.object.isRequired,
    sessionId: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)

    let p = {}
    props.athletes.forEach(a => {
      p[a.id] = a.parameters
    })

    this.state = {
      parameters: p,
    }
  }

  renderFooter = () => {
    const {
      intl: { formatMessage: _ },
      close,
      showSuccessToast,
      showErrorToast,
      movement,
      sessionId,
      athletes
    } = this.props

    const { parameters } = this.state

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">
          <SbButton onClick={close}>{_(t.cancel)}</SbButton>
        </div>
        <div className="sb-modal-center"></div>
        <div className="sb-modal-right">
          <Mutation
            mutation={updateParametersMutation}
            onCompleted={({ session }) => {
              showSuccessToast(_(t.parametersUpdated))
              close()
            }}
            onError={() => {
              showErrorToast(_(t.errorOnUpdate))
            }}
          >
            {(postMutation, { loading }) => (
              <SbButton
                className={'primary'}
                onClick={() => {
                  postMutation({
                    variables: {
                      sessionId,
                      athleteIds: athletes.map(a => a.id),
                      athleteParameters: athletes.map(a => ({
                        ...parameters[a.id],
                      })),
                    },
                  })
                }}
                disabled={
                  Object.keys(parameters).length !== athletes.length ||
                  !Object.keys(parameters).reduce(
                    (acc, id) =>
                      acc && parameters[id] && Object.keys(parameters[id]).length === movement.parameters.length,
                    true,
                  ) ||
                  loading
                }
                loading={loading}
              >
                {_(t.updateSessionParameters)}
              </SbButton>
            )}
          </Mutation>
        </div>
      </div>
    )
  }

  render() {
    const {
      intl: { formatMessage: _ },
      movement,
      athletes,
    } = this.props

    const { parameters } = this.state

    return (
      <SbModal Header={<h1>{_(t.updateSessionParameters)}</h1>} Footer={this.renderFooter()} width={1280} height={650}>
        <p>{_(t.enterMetadata)}:</p>
        <ParameterEditor
          parameters={parameters}
          requiredParameters={movement.parameters}
          athletes={athletes}
          updateParameters={newParameters => {
            this.setState({ parameters: newParameters })
          }}
        />
      </SbModal>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  close: () => dispatch(closeModal()),
  showSuccessToast: (params, config = {}) => showSuccessToast(params, config)(dispatch),
  showErrorToast: (params, config = {}) => showErrorToast(params, config)(dispatch),
})

export const EditSessionParametersModal = injectIntl(
  injectSbLocale(connect(null, mapDispatchToProps)(_EditSessionParametersModal)),
)
