import { permissions as p } from '@/common/permissions'

export const readOwnMeasurementsPermission = p.MEASUREMENTS_READ_OWN

export const measurementsPagePermissions = [p.MEASUREMENTS_READ_ASSIGNED, p.MEASUREMENTS_READ_OWN]

export const downloadRawDataPermission = p.RAWDATA_READ

export const createSessionPermission = p.MEASUREMENTS_CREATE_ASSIGNED
export const reAnalyzePermission = p.MEASUREMENTS_REANALYZE
export const measurementCommentsPermission = p.MEASUREMENTS_COMMENTS
export const invalidatePermission = p.MEASUREMENTS_INVALIDATE_ASSIGNED
