import { USER_LOGOUT } from 'skybase-oauth/oauth/actions'
import { graphQlClient } from '@/common/graphql-api'

export const logoutMiddleware = store => next => action => {
  const { type } = action

  if (type === USER_LOGOUT ) {
    // Clear the GraphQL cache
    graphQlClient.resetStore()
  }

  return next(action)
}
