import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { MESSAGE_MODAL_ID } from 'skybase-ui/skybase-components/sb-message-modal/constants'
import { SbMessageModal } from 'skybase-ui/skybase-components/sb-message-modal/sb-message-modal'
import { translateMessage } from 'skybase-ui/skybase-core/locales'

import { OAuthModals } from 'skybase-oauth/oauth-modals'
import { O_AUTH_MODALS_KEY } from 'skybase-oauth/constants'

import {
  DELETE_USER_CONFIRMATION_MODAL_ID,
  DeleteConfirmationModal as DeleteUserConfirmationModal,
} from '@/containers/users-page'
import { CREATE_USER_MODAL_ID, CreateUserModal } from '@/components/create-user'
import {
  NEW_SESSION_MODAL_ID,
  NewSessionModal,
  EDIT_SESSION_PARAMETERS_MODAL_ID,
  EditSessionParametersModal,
} from '@/containers/measurements-page'

import { getCurrentModalId, getModalData, SimpleModal, SIMPLE_MODAL_ID } from '@/common/modals'

/**
 * @description
 * Opens given modal and prepares its props.
 *
 * @param props
 */
const Modals = props => {
  const {
    currentModalId,
    modalState,
    intl: { formatMessage: _ },
  } = props

  switch (currentModalId) {
    case MESSAGE_MODAL_ID: {
      const { message, title, translate: doTranslation } = modalState
      let messageModalState = modalState

      if (doTranslation) {
        messageModalState = {
          ...modalState,
          message: translateMessage(_, message),
          title: translateMessage(_, title),
        }
      }

      return <SbMessageModal {...props} {...messageModalState} />
    }
    case SIMPLE_MODAL_ID:
      return <SimpleModal {...modalState} />

    case O_AUTH_MODALS_KEY:
      return <OAuthModals {...modalState} />

    case DELETE_USER_CONFIRMATION_MODAL_ID:
      return <DeleteUserConfirmationModal {...modalState} />

    case CREATE_USER_MODAL_ID:
      return <CreateUserModal {...modalState} />

    case NEW_SESSION_MODAL_ID:
      return <NewSessionModal {...modalState} />

    case EDIT_SESSION_PARAMETERS_MODAL_ID:
      return <EditSessionParametersModal {...modalState} />

    default: {
      break
    }
  }

  return <div />
}

Modals.propTypes = {
  intl: intlShape.isRequired,
  currentModalId: PropTypes.string,
  modalState: PropTypes.object, // eslint-disable-line
  currentModalPayload: PropTypes.object, // eslint-disable-line
}

Modals.defaultProps = {
  currentModalId: null,
  modalState: {},
  currentModalPayload: {},
}

const mapStateToProps = state => {
  const currentModalId = getCurrentModalId(state)

  return {
    currentModalId,
    modalState: getModalData(currentModalId, state),
  }
}

export default injectIntl(connect(mapStateToProps)(Modals))
