import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  pageName: {
    id: 'home-page.pageName',
    defaultMessage: 'Home',
  },
  pageIntro: {
    id: 'home-page.pageIntro',
    defaultMessage: 'Kienbaum Analytics',
  },
  newSession: {
    id: 'home-page.newSession',
    defaultMessage: 'New Session'
  },
  viewMeasurements: {
    id: 'home-page.viewMeasurements',
    defaultMessage: 'View Measurements'
  },
  totalAthletes: {
    id: 'home-page.totalAthletes',
    defaultMessage: 'Total Number of Athletes'
  },
  totalTutors: {
    id: 'home-page.totalTutors',
    defaultMessage: 'Total Number of Tutors'
  },
  totalMeasurements: {
    id: 'home-page.totalMeasurements',
    defaultMessage: 'Total Number of Measurements'
  },
})
