// See https://wiki.kistler.com/display/SolutionsLab/Kienbaum+User+Concept for descriptions
export const permissions = {
  ATHLETES_ASSIGNMENTS_READ_ALL: 'kienbaum.athletes.assignments.read.all',
  ATHLETES_READ_ASSIGNED: 'kienbaum.athletes.read.assigned',
  ATHLETES_READ_OWN: 'kienbaum.athletes.read.own',
  ATHLETES_CREATE: 'kienbaum.athletes.create',
  ATHLETES_CREATE_SELFASSIGNED: 'kienbaum.athletes.create.selfassigned',
  ATHLETES_UPDATE_ASSIGNED: 'kienbaum.athletes.update.assigned',
  ATHLETES_UPDATE_OWN: 'kienbaum.athletes.update.own',
  ATHLETES_UPDATE_ALL: 'kienbaum.athletes.update.all',
  ATHLETES_DELETE: 'kienbaum.athletes.delete',
  TUTORS_CREATE: 'kienbaum.tutors.create',
  TUTORS_READ_BASIC: 'kienbaum.tutors.read.basic',
  TUTORS_ASSIGNMENTS_READ_ALL: 'kienbaum.tutors.assignments.read.all',
  TUTORS_ASSIGNMENTS_READ_OWN: 'kienbaum.tutors.assignments.read.own',
  ACCOUNTS_READ_ALL: 'kienbaum.accounts.read.all',
  ACCOUNTS_UPDATE_ALL: 'kienbaum.accounts.update.all',
  ACCOUNTS_UPDATE_OWN: 'kienbaum.accounts.update.own',
  MEASUREMENTS_READ_ASSIGNED: 'kienbaum.measurements.read.assgined',
  MEASUREMENTS_READ_OWN: 'kienbaum.measurements.read.own',
  MEASUREMENTS_CREATE_ASSIGNED: 'kienbaum.measurementes.create.assigned',
  MEASUREMENTS_REANALYZE: 'kienbaum.measurements.reanalyze',
  MEASUREMENTS_INVALIDATE_ASSIGNED: 'kienbaum.measurements.invalidate.assigned',
  KICONNECT_USERS_WRITE: 'kiconnect.users.write',
  KICONNECT_USERS_READ: 'kiconnect.users.read',
  SITECONFIG_READWRITE: 'kienbaum.siteconfig.readwrite',
  MOVEMENTS_READ: 'kienbaum.movements.read',
  RAWDATA_READ: 'kienbaum.rawdata.read',
  MEASUREMENTS_COMMENTS: 'kienbaum.comments.readwrite',
}
