import React, { Component } from 'react'

import { injectIntl } from 'react-intl'
import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { SbListView, SbFullLayout } from 'skybase-ui/skybase-components'

import { Query } from '@apollo/react-components'
import { gql } from '@apollo/client'

import { messages as t } from './home-page-i18n'

const statisticsQuery = gql`
  query {
    statistics {
      numberOfAthletes
      numberOfTutors
      numberOfMeasurements
    }
  }
`

class _HomePage extends Component {
  static propTypes = {
    intl: intlShape.isRequired,
  }

  render() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbFullLayout title={_(t.pageName)}>
        <div className="sb-row">
          <h1>{_(t.pageIntro)}</h1>
          <Query query={statisticsQuery}>
            {({ data, loading }) => (
              <SbListView
                style={{ maxWidth: '350px' }}
                items={[
                  { title: _(t.totalAthletes), item: !loading ? data?.statistics?.numberOfAthletes : '', inline: true },
                  { title: _(t.totalTutors), item: !loading ? data?.statistics?.numberOfTutors : '', inline: true },
                  { title: _(t.totalMeasurements), item: !loading ? data?.statistics?.numberOfMeasurements : '', inline: true },
                ]}
              />
            )}
          </Query>
        </div>
      </SbFullLayout>
    )
  }
}

export const HomePage = injectIntl(_HomePage)
