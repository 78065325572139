import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  enabled: {
    id: 'status-bullet.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'status-bullet.disabled',
    defaultMessage: 'Disabled',
  },
  unknown: {
      id: 'status-bullet.unknown',
      defaultMessage: 'Unknown',
  }
})