import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { SbButton } from 'skybase-ui/skybase-components/sb-button/sb-button'
import { SbModal, SbModalHeader } from 'skybase-ui/skybase-components/sb-modal'
import { closeModal } from 'skybase-ui/skybase-core/base/actions'

const handleOverlayClick = dispatch => dispatch(closeModal())

class _SimpleModal extends Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object),
    message: PropTypes.node,
    title: PropTypes.string,
    type: PropTypes.string,
    onClickOverlay: PropTypes.func,
    closeButton: PropTypes.bool,
    className: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
    buttons: null,
    message: '',
    title: '',
    type: '',
    closeButton: true,
    onClickOverlay: dispatch => handleOverlayClick(dispatch),
  }

  handleButtonOnClick = button => {
    const { dispatch } = this.props
    const { action } = button

    if (action) {
      dispatch(action())
    }
  }

  handleOnClickOverlay = () => {
    const { onClickOverlay, dispatch } = this.props

    onClickOverlay(dispatch)
  }

  renderHeader = () => {
    const { title, closeButton } = this.props
    const onCloseBtnClick = closeButton ? this.handleOnClickOverlay : null

    return <SbModalHeader title={title} onCloseBtnClick={onCloseBtnClick} />
  }

  renderButtons = (buttons, buttonPosition) => {
    return buttons
      .filter(button => button.position === buttonPosition)
      .map((button, index) => {
        const { title, className, position } = button

        return (
          <SbButton
          key={`simple-button-${position}-${index}`} // eslint-disable-line
            className={className}
            onClick={() => {
              this.handleButtonOnClick(button)
            }}
          >
            {title}
          </SbButton>
        )
      })
  }

  renderFooter = () => {
    let { buttons } = this.props

    // by default there is just Ok button displayed, if no buttons prop were passed.
    buttons = buttons || [
      {
        position: 'right',
        title: 'Close',
        action: closeModal,
      },
    ]

    return (
      <div className="sb-modal-footer fl-container fl-justify-end">
        <div className="sb-modal-left">{this.renderButtons(buttons, 'left')}</div>
        <div className="sb-modal-center">{this.renderButtons(buttons, 'center')}</div>
        <div className="sb-modal-right">{this.renderButtons(buttons, 'right')}</div>
      </div>
    )
  }

  render() {
    const { message, type, className } = this.props

    return (
      <SbModal
        onClickOverlay={this.handleOnClickOverlay}
        Header={this.renderHeader()}
        Footer={this.renderFooter()}
        width={600}
        height={400}
        className={`sb-message-modal ${type} ${className}`}
      >
        <div className="sb-message-modal-content">
          <div className="sb-message-text">{message}</div>
        </div>
      </SbModal>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  }
}

export const SimpleModal = connect(null, mapDispatchToProps)(_SimpleModal)
