import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { AppContainer } from 'react-hot-loader'

import { SbToastrContainer } from 'skybase-ui/skybase-components/sb-toastr'
import { setAppRoot, initialize } from 'skybase-ui/skybase-core/base/actions'
import { getAppRoot } from 'skybase-ui/skybase-core/utils/browser'
import { notificationsLocalStorageService } from 'skybase-ui/skybase-core/notifications/notifications-localstorage-service'
import { emitterLogger } from 'skybase-ui/skybase-core/emitter/emitter-logger'
import { emitToastOnErrorService } from 'skybase-ui/skybase-components/sb-toastr/services'
import { combineLocations } from 'skybase-ui/skybase-core/notifications/combine-locations'

import { OAuth } from 'skybase-oauth/oauth'
import { OAuthComponentFactory } from 'skybase-oauth/oauth/oauth-component-factory'

import Modals from '@/containers/modals/modals'
import { CustomIntlProvider } from '@/containers/custom-intl-provider'
import { initializeApp } from '@/common/actions'
import { initializeLanguagesAndCulture } from '@/common/locales'

//import { clickTrackingServices } from './app/common/services'

import { store } from '@/stores'
import Routes from '@/routes'
import { getMode } from './utils'

import { oAuthConfig } from '@/common/oauth'

import { graphQlClient } from '@/common/graphql-api'
import { ApolloProvider } from '@apollo/client'

// initialize skybase-oauth services
OAuth.setConfig(oAuthConfig)

// initialize the store
store.dispatch(setAppRoot(getAppRoot()))

// initialize the storage for the notification service
notificationsLocalStorageService(store)

// initialize locations for redirect for notifications
combineLocations({}) // Add custom locations to parameters here

// initialize toast to error message service.
emitToastOnErrorService(store.dispatch)

if (getMode() !== 'production') {
  emitterLogger()
}

// Sets up languages and cultures.
initializeLanguagesAndCulture(store.dispatch)

// Initialize the internals to this app
store.dispatch(initializeApp())
// Initialize all of the SkyBase included modules
store.dispatch(initialize())

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <CustomIntlProvider>
          <OAuthComponentFactory>
            <ApolloProvider client={graphQlClient}>
              <Fragment>
                <Routes />
                <Modals />
                <SbToastrContainer position="top-right" />
              </Fragment>
            </ApolloProvider>
          </OAuthComponentFactory>
        </CustomIntlProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('app'),
  )
}

render()

//TODO: Check if this is needed for most recent version of react-app.

if (module.hot) {
  // Enable Webpack hot module replacement for components
  module.hot.accept('@/containers/app/app.js', () => {
    // render(require('./main-component').MainComponent) // eslint-disable-line global-require
    render()
  })
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('@/reducers', () => {
    const nextRootReducer = require('@/reducers') // eslint-disable-line global-require

    store.replaceReducer(nextRootReducer)
  })
}
