import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  name: {
    id: 'parameter-editor.name',
    defaultMessage: 'Name',
  },
  female: {
    id: 'parameter-editor.female',
    defaultMessage: 'female',
  },
  male: {
    id: 'parameter-editor.male',
    defaultMessage: 'male',
  },
  birthdate: {
    id: 'parameter-editor.birthdate',
    defaultMessage: 'Date of Birth',
  },
  sex: {
    id: 'parameter-editor.sex',
    defaultMessage: 'Sex',
  },
  weight: {
    id: 'parameter-editor.weight',
    defaultMessage: 'Weight',
  },
  height: {
    id: 'parameter-editor.height',
    defaultMessage: 'Height',
  },
  frontAngle: {
    id: 'parameter-editor.frontAngle',
    defaultMessage: 'Front Angle',
  },
  rearAngle: {
    id: 'parameter-editor.rearAngle',
    defaultMessage: 'Rear Angle',
  },
  frontDistance: {
    id: 'parameter-editor.frontDistance',
    defaultMessage: 'Front Distance',
  },
  rearDistance: {
    id: 'parameter-editor.rearDistance',
    defaultMessage: 'Rear Distance',
  },
  hurdleHeight: {
    id: 'parameter-editor.hurdleHeight',
    defaultMessage: 'Hurdle Height',
  },
  hurdleDistance: {
    id: 'parameter-editor.hurdleDistance',
    defaultMessage: 'Hurdle Distance',
  },
})
