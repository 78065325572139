/**
 * CAUTION: This is quite a quick hack. :)
 *
 * This component is implemented using global variables etc, which is ugly. I did this to prevent
 * any performance issues that come with managed components (re-rendering etc.)
 *
 * For a proper implementation, as much as possible needs to be properly integrated into the component.
 * Anything that can't be encapsuled in the component needs to be properly managed.
 *
 */

import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'

import ApexCharts from 'apexcharts'
import Chart from 'react-apexcharts'

import { gql } from '@apollo/client'
import { Query } from '@apollo/react-components'

import { SbButton, SbButtonBar, SbRadioButton } from 'skybase-ui/skybase-components'

import { messages as t } from './measurements-page-i18n'

import { colors } from './constants'

const videoUrlQuery = gql`
  query assignedFileDownloadUrl($id: ID!) {
    assignedFileDownloadUrl(id: $id)
  }
`

// Force Charts
const forceChartIds = ['Fz', 'Fy', 'Fx']

// References
let v1 = null,
  v2 = null,
  seekbar = null,
  time = null,
  forceCharts = null,
  characteristicsHash = null

// Source: https://stackoverflow.com/a/15710692/4361275
const hashCode = s =>
  JSON.stringify(s)
    .split('')
    .reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)

const getRefs = () => {
  if (!v1 || !v2 || !seekbar) {
    v1 = document.getElementById(`video-0`)
    v2 = document.getElementById(`video-1`)
    seekbar = document.getElementById(`seekbar`)
    time = document.getElementById(`time`)
  }
}

// How many s the Videos may be apart
const skewTolerance = 0.005

// Debounce Events
const debounceThreshold = 10
let lastEvent = -Infinity

const isRecent = () => {
  const now = Date.now()
  const diff = now - lastEvent
  if (diff > debounceThreshold) {
    lastEvent = now
    return true
  }
  return false
}

const updateTimeDisplayAndCursor = v => {
  getRefs()
  time.innerHTML = `${parseFloat(v).toFixed(3)}s`

  if (forceCharts) {
    try {
      forceChartIds.forEach(c => {
        ApexCharts.exec(`${c}Chart`, 'clearAnnotations')
        ApexCharts.exec(`${c}Chart`, 'addXaxisAnnotation', {
          x: v,
          opacity: 1,
          borderColor: '#f00',
        })
      })
    } catch (error) {
      return null
    }
  }
}

const syncState = () => {
  requestAnimationFrame(() => {
    getRefs()
    if (seekbar === null) {
      return;
    }
    updateTimeDisplayAndCursor(seekbar.value)

    if (v1 && v2 && isRecent()) {
      if ((v1.paused && v2.paused) || Math.abs(v1.currentTime - v2.currentTime) > skewTolerance) {
        v2.currentTime = seekbar.value
        v1.currentTime = seekbar.value
      }
      if (!v1.paused && v2.paused) {
        v2.play()
      }
      if (v1.paused && !v2.paused) {
        v2.pause()
        setTimeout(() => {
          seekbar.value = v2.currentTime
          syncState()
        }, debounceThreshold * 1.1)
      }
    }
  })
}

const syncVideoToSeekbar = () => {
  getRefs()

  seekbar.value = v1.currentTime
  updateTimeDisplayAndCursor(seekbar.value)

  if (!v1.paused) {
    requestAnimationFrame(() => {
      syncVideoToSeekbar()
    })
  }
}

const playPause = () => {
  getRefs()
  if (v1.paused) {
    v1.play()
    requestAnimationFrame(() => {
      syncVideoToSeekbar()
    })
  } else if (!v1.paused) {
    v1.pause()
    seekbar.value = v2.currentTime
  }
}

// Hardcoded constant frame rate
const frame = 1 / 200
const frameShift = forward => {
  getRefs()
  if (v1.paused) {
    const currentValue = parseFloat(seekbar.value)
    if (forward) {
      seekbar.value = Math.min(currentValue + frame, 10)
    } else {
      seekbar.value = Math.max(currentValue - frame, 0)
    }
    syncState()
  }
}

const setPlaybackRate = v => {
  getRefs()
  v1.playbackRate = v
  v2.playbackRate = v
}

const getVideo = (ids, i, onLoaded = () => {}) => {
  const vId = ids[i]
  return (
    <div key={vId} style={{ width: '45%' }}>
      {vId && (
        <Query
          query={videoUrlQuery}
          pollInterval={55 * 60 * 1000} // get a new URL before the old one expires
          variables={{ id: vId }}
        >
          {({ data }) => {
            if (data?.assignedFileDownloadUrl) {
              return (
                <React.Fragment>
                  <video
                    id={`video-${i}`}
                    width="100%"
                    height="auto"
                    preload="auto"
                    src={data.assignedFileDownloadUrl}
                    onPlay={() => {
                      if (!i) {
                        syncState()
                      }
                    }}
                    onSeeked={() => {
                      if (!i) {
                        syncState()
                      }
                    }}
                    onPause={ev => {
                      if (!i) {
                        syncState()
                      }
                    }}
                    onLoadedMetadata={ev => {
                      if (!i) {
                        getRefs()
                        seekbar.max = ev.target.duration
                        seekbar.disabled = false
                        onLoaded()
                      }
                    }}
                  />
                </React.Fragment>
              )
            }
            return null
          }}
        </Query>
      )}
    </div>
  )
}

const renderForceCharts = ({ measurementId, characteristics, forceSuffixes, intl }) => {
  const { formatMessage: _ } = intl

  characteristicsHash = hashCode(characteristics)

  return (
    <React.Fragment>
      <div style={{ height: '30px' }} />
      <div style={{ textAlign: 'center' }}>
        <h3>{_(t.forceTimeSeries)}</h3>
      </div>
      {characteristics &&
        forceChartIds.map((c, idx) => {
          if (!characteristics[c]) return null

          const channel = characteristics[c]
          if (!channel.xValues) return null
          const series = [
            {
              name: c,
              data: channel.values.map((v, i) => [channel.xValues[i], v === 'NaN' ? null : v.toFixed(0)]),
            },
          ]

          if (Array.isArray(forceSuffixes)) {
            forceSuffixes.forEach(s => {
              const name = `${c}${s}`
              if (characteristics[name]) {
                const channel = characteristics[name]
                series.push({
                  name,
                  data: channel.values.map((v, i) => [channel.xValues[i], v === 'NaN' ? null : v.toFixed(0)]),
                })
              }
            })
          }

          const options = {
            chart: {
              id: `${c}Chart`,
              type: 'line',
              group: `${measurementId}Forces`,
              animations: {
                enabled: false,
              },
            },
            stroke: {
              width: 1,
            },
            colors: [colors[idx], colors[idx + forceChartIds.length], colors[idx + forceChartIds.length + 1]],
            xaxis: {
              type: 'numeric',
              title: {
                text: `${_(t.time)} [s]`,
                style: {
                  fontSize: '10px',
                },
              },
            },
            yaxis: {
              title: { text: `${_(t[c])} [N]`, style: { fontSize: '10px' } },
              labels: {
                minWidth: 40,
              },
            },
            tooltip: {
              x: {
                show: false,
                formatter: v => `${v.toFixed(3)} s`,
              },
              y: {
                formatter: v => `${v} N`,
              },
            },
          }

          return <Chart key={`${measurementId}-${c}`} options={options} series={series} height={150} />
        })}
    </React.Fragment>
  )
}

class _SyncVideoPlayer extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isPlaying: false,
      playbackRate: 1,
      loaded: false,
      loadedVideoIds: null,
    }

    // Reset global variables.
    v1 = null
    v2 = null
    seekbar = null
    time = null

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleKeyUp = this.handleKeyUp.bind(this)
  }

  componentDidUpdate() {
    const { keyboardShortcutsEnabled } = this.props
    if (keyboardShortcutsEnabled) {
      document.body.addEventListener('keydown', this.handleKeyDown)
      document.body.addEventListener('keyup', this.handleKeyUp)
    }
    else {
      document.body.removeEventListener('keydown', this.handleKeyDown)
      document.body.removeEventListener('keyup', this.handleKeyUp)
    }
  }

  componentDidMount() {
    const { keyboardShortcutsEnabled } = this.props
    if (keyboardShortcutsEnabled) {
      document.body.addEventListener('keydown', this.handleKeyDown)
      document.body.addEventListener('keyup', this.handleKeyUp)
    }
  }

  componentWillUnmount() {
    const { keyboardShortcutsEnabled } = this.props
    if (keyboardShortcutsEnabled) {
      document.body.removeEventListener('keydown', this.handleKeyDown)
      document.body.removeEventListener('keyup', this.handleKeyUp)
    }
  }

  handleKeyDown(e) {
    const { loaded } = this.state
    if (loaded) {
      switch (e.key) {
        case ' ':
          this.onPlayPauseClick()
          e.preventDefault()
          break
        case 'ArrowLeft':
          this.shiftFrame(false)
          e.preventDefault()
          break
        case 'ArrowRight':
          this.shiftFrame(true)
          e.preventDefault()
          break
        default:
          break
      }
    }
  }

  handleKeyUp(e) {
    switch (e.key) {
      case ' ':
      case 'ArrowLeft':
      case 'ArrowRight':
        e.preventDefault()
        e.stopPropagation()
        break
      default:
        break
    }
  }

  shiftFrame(direction) {
    const { isPlaying } = this.state
    if (isPlaying) {
      this.onPlayPauseClick()
    } else {
      frameShift(direction)
    }
  }

  onPlayPauseClick() {
    const { isPlaying } = this.state
    playPause()
    this.setState({ isPlaying: !isPlaying })
  }

  setPlaybackSpeed(r) {
    setPlaybackRate(r)
    this.setState({ playbackRate: r })
  }

  setTime() {
    const { isPlaying } = this.state
    if (isPlaying) {
      this.onPlayPauseClick()
    }
    syncState()
  }

  render() {
    const { isPlaying, playbackRate, loaded, loadedVideoIds } = this.state

    const { videoIds, characteristics } = this.props

    if ((characteristics && (!forceCharts || characteristicsHash !== hashCode(characteristics))) || !characteristics) {
      forceCharts = renderForceCharts(this.props)
    }

    // Make sure the global variables get reset when the videoId property changes
    if (loadedVideoIds !== videoIds) {
      // Reset global variables
      v1 = null
      v2 = null
      if (seekbar) {
        seekbar.value = 0
      }
      time = null
      syncState()
    }
    return (
      <div>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
            {getVideo(videoIds, 0, () => {
              this.setState({ loaded: true, loadedVideoIds: videoIds })
            })}
            <div
              style={{
                width: '10%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ width: '100%', textAlign: 'center', marginBottom: '1em' }}>
                <SbButton
                  icon="sbi-chevron-double-right"
                  disabled={!loaded}
                  onClick={() => {
                    this.shiftFrame(true)
                  }}
                />
              </div>
              <div style={{ width: '100%', textAlign: 'center', marginBottom: '1em' }}>
                <SbButton
                  icon={isPlaying ? 'sbi-player-pause' : 'sbi-player-play'}
                  disabled={!loaded}
                  onClick={() => {
                    this.onPlayPauseClick()
                  }}
                />
              </div>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <SbButton
                  icon="sbi-chevron-double-left"
                  disabled={!loaded}
                  onClick={() => {
                    this.shiftFrame(false)
                  }}
                />
              </div>
              <div style={{ width: '100%', textAlign: 'center', marginTop: '2em' }}>
                <div id="time">0.000s</div>
              </div>
            </div>
            {getVideo(videoIds, 1)}
          </div>
          <div
            style={{
              width: '100%',
              height: '50px',
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1em',
            }}
          >
            <div style={{ width: '50%' }}>
              <input
                id="seekbar"
                type="range"
                min="0"
                max="0"
                step="0.005"
                defaultValue="0"
                style={{ width: '100%' }}
                disabled
                onChange={ev => {
                  this.setTime()
                }}
              />
            </div>
            <div style={{ width: '40%' }}>
              <SbButtonBar disabled={!loaded}>
                {[0.1, 0.5, 1.0].map(r => (
                  <SbRadioButton
                    key={`playbackrate-${r}`}
                    checked={playbackRate === r}
                    onClick={() => {
                      this.setPlaybackSpeed(r)
                    }}
                  >
                    {r}x
                  </SbRadioButton>
                ))}
              </SbButtonBar>
            </div>
          </div>
        </div>
        <div style={{ width: '100%' }}>{forceCharts}</div>
      </div>
    )
  }
}

export const SyncVideoPlayer = injectIntl(_SyncVideoPlayer)
