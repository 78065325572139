import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  usermanagement: {
    id: 'users-page.usermanagement',
    defaultMessage: 'User Management'
  },
  tutors: {
    id: 'users-page.tutors',
    defaultMessage: 'Tutors',
  },
  athletes: {
    id: 'users-page.athletes',
    defaultMessage: 'Athletes',
  },
  previous: {
    id: 'users-page.previous',
    defaultMessage: 'previous'
  },
  next: {
    id: 'users-page.next',
    defaultMessage: 'next'
  },
  showRecords: {
    id: 'users-page.showRecords',
    defaultMessage: 'Show records'
  },
  details: {
    id: 'users-page.details',
    defaultMessage: 'Account Details'
  },
  pleaseselectuser: {
    id: 'users-page.pleaseselectuser',
    defaultMessage: 'Please select an account to edit.'
  },
  enabled: {
    id: 'users-page.enabled',
    defaultMessage: 'Enabled'
  },
  disabled: {
    id: 'users-page.disabled',
    defaultMessage: 'Disabled'
  },
  firstName: {
    id: 'users-page.firstName',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'users-page.lastName',
    defaultMessage: 'Last Name'
  },
  email: {
    id: 'users-page.email',
    defaultMessage: 'Email'
  },
  loginStatusUnknown: {
    id: 'users-page.loginStatusUnknown',
    defaultMessage: 'The login details of this user could not be fetched from the authentication service. Please contact Kistler in case this issue persists.'
  },
  type: {
    id: 'users-page.type',
    defaultMessage: 'Type'
  },
  canLogin: {
    id: 'users-page.canLogin',
    defaultMessage: 'Login'
  },
  isRequired: {
    id: 'users-page.isRequired',
    defaultMessage: 'is required'
  },
  updateUser: {
    id: 'users-page.updateUser',
    defaultMessage: 'Update User'
  },
  updateSuccessful: {
    id: 'users-page.updateSuccessful',
    defaultMessage: 'User updated successfully'
  },
  errorOnUpdate: {
    id: 'users-page.errorOnUpdate',
    defaultMessage: 'An error occurred when updating the user'
  },
  deleteUser: {
    id: 'users-page.deleteUser',
    defaultMessage: 'Delete User'
  },
  tutoringTrainers: {
    id: 'users-page.tutoringTrainers',
    defaultMessage: 'Tutoring Trainers'
  },
  tutoredAthletes: {
    id: 'users-page.tutoredAthletes',
    defaultMessage: 'Tutored Athletes'
  },
  tutor: {
    id: 'users-page.tutor',
    defaultMessage: 'Tutor'
  },
  athlete: {
    id: 'users-page.athlete',
    defaultMessage: 'Athlete'
  },
  invalidEmail: {
    id: 'users-page.invalidEmail',
    defaultMessage: 'Invalid Email Address'
  },
  search: {
    id: 'users-page.search',
    defaultMessage: 'Search'
  },
  allAccounts: {
    id: 'users-page.allAccounts',
    defaultMessage: 'All Accounts'
  },
  tutorsOnly: {
    id: 'users-page.tutorsOnly',
    defaultMessage: 'Tutors Only'
  },
  athletesOnly: {
    id: 'users-age.athletesOnly',
    defaultMessage: 'Athletes Only'
  },
  clearFilters: {
    id: 'users-page.clearFilters',
    defaultMessage: 'Clear Filters'
  },
  sureToDelete: {
    id: 'users-page.sureToDelete',
    defaultMessage: 'Are you sure you want to delete user {name} and all the associated data'
  },
  cannotBeUndone: {
    id: 'users-page.cannotBeUndone',
    defaultMessage: 'This cannot be undone'
  },
  cancel: {
    id: 'users-page.cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'users-page.delete',
    defaultMessage: 'Delete'
  },
  deletionSuccessful: {
    id: 'users-page.deletionSuccessful',
    defaultMessage: 'Successfully deleted user {name}'
  },
  errorOnDelete: {
    id: 'users-page.errorOnDelete',
    defaultMessage: 'Error when attempting to delete user {name}'
  },
  addNewUser: {
    id: 'users-page.addNewUser',
    defaultMessage: 'Add new User'
  },
  sex: {
    id: 'users-page.sex',
    defaultMessage: 'Sex'
  },
  male: {
    id: 'users-page.male',
    defaultMessage: 'Male'
  },
  female: {
    id: 'users-page.female',
    defaultMessage: 'Female'
  },
  birthdate: {
    id: 'users-page.birthdate',
    defaultMessage: 'Date of Birth'
  },
})
