import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import { intlShape } from 'skybase-ui/skybase-core/shapes'
import { SbMenuItem, SbMenu } from 'skybase-ui/skybase-components'

import { usersPagePermissions } from '@/containers/users-page'
import { measurementsPagePermissions } from '@/containers/measurements-page'
import { AclControl } from 'skybase-oauth/auth/acl-control'

import { messages as t } from './menu-i18n'
import { MENU_AUTO_COLLAPSING_WIDTH } from './menu-constants'

import './menu.scss'

class Menu extends Component {
  static propTypes = {
    currentUrl: PropTypes.string.isRequired,
    onMenuItemClick: PropTypes.func.isRequired,
    leftMenuIsCollapsed: PropTypes.bool,
    intl: intlShape.isRequired,
  }

  static defaultProps = {
    leftMenuIsCollapsed: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      isCollapsed: false,
    }

    this.handleResize = this.handleResize.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)

    this.handleResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  getMenuItemProps = title => {
    const { isCollapsed } = this.state
    const { currentUrl, onMenuItemClick, leftMenuIsCollapsed } = this.props

    return {
      title,
      currentUrl,
      onClick: onMenuItemClick,
      isCollapsed: leftMenuIsCollapsed || isCollapsed,
      hint: {
        position: {
          vertical: 'middle',
          horizontal: 'right',
        },
        hintData: title,
        showOnlyOnCollapse: true,
      },
    }
  }

  handleResize() {
    const { isCollapsed } = this.state
    const width = window.innerWidth

    // the constant MENU_AUTO_COLLAPSING_WIDTH is the breakpoint for the collapse/expand by CSS media query.
    if (width <= MENU_AUTO_COLLAPSING_WIDTH && !isCollapsed) {
      this.setState({ isCollapsed: true })
    } else if (width > MENU_AUTO_COLLAPSING_WIDTH && isCollapsed) {
      this.setState({ isCollapsed: false })
    }
  }

  render() {
    const {
      intl: { formatMessage: _ },
    } = this.props

    return (
      <SbMenu>
        <SbMenuItem url="/" icon={<i className="sbi-nav-home" />} {...this.getMenuItemProps(_(t.home))} />
        <AclControl writePermissions={measurementsPagePermissions}>
          <SbMenuItem
            url="/measurements"
            icon={<i className="sbi-nav-test" />}
            {...this.getMenuItemProps(_(t.measurements))}
          />
        </AclControl>
        <AclControl writePermissions={usersPagePermissions} requireAll>
          <SbMenuItem
            url="/users"
            icon={<i className="sbi-nav-user-management" />}
            {...this.getMenuItemProps(_(t.users))}
          />
        </AclControl>
        <SbMenuItem url="/about" icon={<i className="sbi-nav-info" />} {...this.getMenuItemProps(_(t.about))} />
      </SbMenu>
    )
  }
}

const mapStateToProps = state => {
  const isCollapsed = state.getIn ? state.getIn(['leftColumn', 'isCollapsed']) : state.leftColumn.isCollapsed

  return {
    leftMenuIsCollapsed: isCollapsed,
  }
}

export default injectIntl(connect(mapStateToProps)(Menu))
