import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  about: {
    id: 'menu.about',
    defaultMessage: 'About',
  },
  home: {
    id: 'menu.home',
    defaultMessage: 'Home',
  },
  measurements: {
    id: 'menu.measurements',
    defaultMessage: 'Measurements'
  },
  users: {
    id: 'menu.users',
    defaultMessage: 'User Management'
  },
  settings: {
    id: 'menu.settings',
    defaultMessage: 'Settings'
  }
})
